import React from "react";
import styled from "styled-components";

import pendingIcon from "../../../assets/icons/status-pending-icon.svg";

export default function OndoingSingleCard({
    setSinglePage,
    item,
    getSingleAssignment,
}) {
    return (
        <>
            <SingleCard>
                <Top>
                    <ContentDiv>
                        <TopDiv>
                            <Title>{item.assignment.title}</Title>
                            <SubDiv>
                                <TotalQuestion>
                                    Total Questions :{" "}
                                    {item.assignment.total_questions}
                                </TotalQuestion>
                                <TotalMark>
                                    Total Marks : {item.assignment.total_mark}
                                </TotalMark>
                            </SubDiv>
                            {/* <PContent>
							Lorem, ipsum dolor sit amet consectetur adipisicing
							elit. Eligendi alias unde sunt odio, suscipi
						</PContent> */}
                        </TopDiv>
                    </ContentDiv>
                    <div>
                        {item.status === "Active" && (
                            <StatusButton>
                                <StatusIcon src={pendingIcon} alt="icon" />
                                <StatusText>Pending</StatusText>
                            </StatusButton>
                        )}
                    </div>
                </Top>
                <ButtonDiv>
                    <ViewButton
                        className={
                            item.status === "Active" ||
                            item.status === "Closed" ||
                            item.status === "Submitted"
                                ? ""
                                : "locked"
                        }
                        onClick={() => {
                            if (
                                item.status === "Active" ||
                                item.status === "Submitted" ||
                                item.status === "Closed"
                            ) {
                                getSingleAssignment(item.id);
                            }
                        }}
                    >
                        {item.status === "Active" ||
                        item.status === "Submitted" ||
                        item.status === "Closed"
                            ? "View"
                            : "Locked"}
                    </ViewButton>
                </ButtonDiv>
            </SingleCard>
        </>
    );
}

const SingleCard = styled.div`
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    background: #ffffff;
    border: 1px solid #9d9d9d;
    border-radius: 16px;
    padding: 24px;
    /* cursor: pointer; */
`;
const Top = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
`;
const ContentDiv = styled.div``;
const TopDiv = styled.div`
    /* uesr-select: text !important; */
`;
const Title = styled.h4`
    font-weight: 600;
    /* width: 82%; */

    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #010101;
    /* user-select: text; */
`;
const SubDiv = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
`;
const TotalQuestion = styled.h5`
    color: #9d9d9d;
    font-size: 14px;
`;
const TotalMark = styled.h5`
    color: #9d9d9d;
    font-size: 14px;
    margin-left: 30px;
`;
const PContent = styled.div`
    margin-top: 16px;
    color: #9d9d9d;
    font-size: 16px;
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;
const StatusButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    background: #ffecd9;
    box-sizing: border-box;
    border-radius: 63px;
`;
const StatusIcon = styled.img`
    width: 12px;
    display: block;
    object-fit: cover;
    margin-right: 6px;
`;
const StatusText = styled.p`
    color: #ffa143;
    font-size: 13px;
`;
const ViewButton = styled.button`
    display: inline-block;
    padding: 12px 32px;
    color: #fff;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 600;
    background: #917afd;
    cursor: pointer;
    margin-top: 10px;
    &.locked {
        background: #c3c3c3;
        cursor: not-allowed;
    }
`;

const Icon = styled.img`
    display: block;
    object-fit: cover;
    width: 12px;
`;
