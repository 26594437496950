import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

// Material-Ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

// Components
import ProgramVideoSection from "../../includes/programsCommonComponents/singlePageBasedOnType/ProgramVideoSection";
import ProgramAudioSection from "../../includes/programsCommonComponents/singlePageBasedOnType/ProgramAudioSection";
import ProgramNoteSection from "../../includes/programsCommonComponents/singlePageBasedOnType/ProgramNoteSection";
import EmptyPage from "../../includes/emptyPages/EmptyPage";

import LessonCard from "../../includes/programsCommonComponents/lessonOrTopicCards/LessonCard";
import TopicCard from "../../includes/programsCommonComponents/lessonOrTopicCards/TopicCard";
import SubTopicCard from "../../includes/programsCommonComponents/lessonOrTopicCards/SubTopicCard";
import ProgramBeginPage from "../../includes/programsCommonComponents/singlePageBasedOnType/ProgramBeginPage";
import emptyPic from "../../../assets/icons/webinar-empty-icon.svg";
import warningIcon from "../../../assets/icons/warning-icon.svg";
import successIcon from "../../../assets/icons/success-tick.svg";

import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import Loader from "../../includes/loader/Loader";
import ProgramPdf from "../../includes/programsCommonComponents/singlePageBasedOnType/ProgramPdf";
import ProgramVideo from "../../includes/programsCommonComponents/singlePageBasedOnType/ProgramVideo";
import PracticeInitial from "../../includes/practicemodals/PracticeInitial";
import PracticeModal from "../../includes/practicemodals/PracticeModal";

export default function MySubjects() {
	const { state } = useContext(Context);

	// const [content, setContent] = useState();
	const [selectedLesson, setSelectedLesson] = useState(null);
	const [selectedTopic, setSelectedTopic] = useState(null);
	const [selectedContent, setSelectedContent] = useState(null);
	// const [singleContentId, setSingleContentId] = useState("");
	const [syllabus, setSyllabus] = useState({});
	const [practiceData, setPracticeData] = useState([]);
	const [subjectName, setSubjectName] = useState("");
	const [nextContentId, setNextContentId] = useState();
	const [isLoading, setLoading] = useState(true);
	const [isContentLoading, setIsContentLoading] = useState(true);
	const [isPracticeLoading, setIsPracticeLoading] = useState(true);
	const [isNextTopic, setIsNextTopic] = useState(false);
	const [isNextLesson, setIsNextLesson] = useState(false);
	const [isNextPractice, setIsNextPactice] = useState(false);

	const [isWarningModal, setIsWarningModal] = useState(false);
	const [isSuccessModal, setIsSuccessModal] = useState(false);
	const [lessons, setLessons] = useState([]);
	const { id } = useParams();

	const history = useHistory();

	const getContents = (val) => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/subject-contents/${id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					if (val !== "mark_as_complete") {
						getSingleContent(data.last_learned_content);
						setSelectedLesson(data.last_learned_lesson);
						setSelectedTopic(data.last_learned_topic);
						// setLessons(data.data);
					}
					setLoading(false);
					setLessons(data.data);
					setSubjectName(data.subject_name);
					// if (val === "mark_as_complete") {
					// 	setSubjectName(data.subject_name);
					// 	setLessons(data.data);
					// 	setLoading(false);

					// } else {
					// 	// setSelectedLesson(
					// 	// 	data.last_learned_lesson === null
					// 	// 		? data.data[0].id
					// 	// 		: data.last_learned_lesson
					// 	// );
					// 	// setSelectedTopic(
					// 	// 	data.last_learned_topic === null
					// 	// 		? data.data[0].topics[0].id
					// 	// 		: data.last_learned_topic
					// 	// );
					// 	// setSelectedContent(
					// 	//     data.last_learned_content === null
					// 	//         ? data.data[0].topics[0].contents[0]
					// 	//         : data.last_learned_content
					// 	// );
					// 	// setSingleContentId(
					// 	// 	data.last_learned_content === null
					// 	// 		? data.data[0].topics[0].contents[0].id
					// 	// 		: data.last_learned_content
					// 	// );
					// 	setSubjectName(data.subject_name);
					// 	setLessons(data.data);
					// 	// if( data.last_learned_content === null){
					// 	// }
					// 	getSingleContent(
					// 		data.last_learned_content === null
					// 			? data.data[0].topics[0].contents[0].id
					// 			: data.last_learned_content
					// 	);
					// 	// getSingleContent(data.last_learned_content); =====================
					// 	setLoading(false);
					// }
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	const getSyllabus = () => {
		// setLoading(true);

		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/subject-syllabus/${id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSyllabus(data.data);
					// setLoading(false);
				} else {
					// setLoading(false);
				}
			})
			.catch((error) => {
				// setLoading(false);
			});
	};

	const getSingleContent = (contentId, val) => {
		setIsContentLoading(true);

		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/subject-content-details/${contentId}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSelectedContent(data.data);
					setIsNextPactice(data.data.next_practice);
					setIsContentLoading(false);
					// // setNextContentId(data.next_content_id); ////wait
					// // setSelectedContent(data.data.content.id);
					// if (val === "mark_as_complete") {
					// } else {
					// 	setSelectedContent(data.data); //okay
					// 	// if (data.next_topic) {
					// 	setSelectedTopic(data.data.topic);
					// 	// }
					// 	// if (data.next_lesson) {
					// 	setSelectedLesson(data.data.lesson);
					// }
					// // }
					// setTimeout(() => {
					// 	setIsContentLoading(false);
					// }, 200);
				} else {
					setTimeout(() => {
						setIsContentLoading(false);
						alert("It is taking longer than usual");
					}, 2000);
				}
			})
			.catch((error) => {
				setIsContentLoading(false);
			});
	};

	const updateContentStatus = (contentId) => {
		let accessToken = state.user_details.access_token;
		var formdata = new FormData();
		formdata.append("content_status", "completed");
		formdata.append("content", contentId);

		baseConfig
			.post("/students/content-status/", formdata, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					// defaultValues();
					// history.push(`/subject/${data.data.id}`);
					getSingleContent(data.next_content_id, "mark_as_complete");
					// setTimeout(() => {
					getContents("mark_as_complete");
					// }, 300);
					setSelectedTopic(data.topic_id);
					setSelectedLesson(data.lesson_id);
				} else {
					alert("message");
				}
			})
			.catch((error, response) => {});
	};

	const getLessonPractice = () => {
		setIsPracticeLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/lesson-practices/${selectedLesson}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setPracticeData(data);
					// setNextContentId(data.next_content_id);
					// setSelectedContent(data.data);
					setTimeout(() => {
						setIsPracticeLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setIsPracticeLoading(false);
					}, 200);
				}
			})
			.catch((error) => {
				setIsPracticeLoading(false);
			});
	};

	useEffect(() => {
		getContents();
		// getSyllabus(); =========syllabus
		// getSingleContent();
		// getLessonPractice();
	}, []);

	useEffect(() => {
		// getContents();
		// getSyllabus(); =========syllabus
		// getSingleContent();
		if (isNextPractice === true) {
			getLessonPractice();
		}
	}, [isNextPractice]);

	// Practice Modals
	const [openInitialModal, setOpenInitialModal] = useState(false);
	const [openPracticeModal, setOpenPracticeModal] = useState(false);

	const handleOpenInitialModal = () => {
		setOpenInitialModal(true);
	};
	const handleCloseInitialModal = () => {
		setOpenInitialModal(false);
	};
	const handleOpenPracticeModal = () => {
		setOpenPracticeModal(true);
	};
	const handleClosePracticeModal = () => {
		setOpenPracticeModal(false);
	};

	return (
		<MainContainer>
			{!isLoading ? (
				<>
					{/* <Button
                        // style={{ display: "none" }}
                        onClick={handleOpenInitialModal}
                    >
                        Open modal
                    </Button> */}
					<PracticeInitial
						openInitialModal={openInitialModal}
						setOpenInitialModal={setOpenInitialModal}
						handleOpenInitialModal={handleOpenInitialModal}
						handleCloseInitialModal={handleCloseInitialModal}
						handleOpenPracticeModal={handleOpenPracticeModal}
						getLessonPractice={getLessonPractice}
					/>
					{openPracticeModal === true && (
						<PracticeModal
							setIsWarningModal={setIsWarningModal}
							setIsSuccessModal={setIsSuccessModal}
							data={practiceData}
							isPracticeLoading={isPracticeLoading}
							getLessonPractice={getLessonPractice}

							// updateContentStatus={updateContentStatus}
						/>
					)}
					<TopContainer>
						<TextDiv>
							<PathText
								onClick={() => {
									history.push("/program/");
								}}
							>
								My Program
							</PathText>
							<Slash>&nbsp;/&nbsp;</Slash>
							<PathTextBlack>{subjectName}</PathTextBlack>
						</TextDiv>
						{Object.keys(syllabus).length !== 0 && (
							<DownloadSyllabus
								href={syllabus.syllabus}
								target="blank"
							>
								Download Syllabus
							</DownloadSyllabus>
						)}
						<EmptyDiv></EmptyDiv>
					</TopContainer>
					<ContentBox>
						<LeftPart>
							{!isContentLoading ? (
								selectedContent ? (
									selectedContent.content_type == 2 ? (
										<ProgramVideoSection
											data={selectedContent}
											subjectName={subjectName}
											updateContentStatus={
												updateContentStatus
											}
											isNextPractice={isNextPractice}
											setOpenPracticeModal={
												setOpenPracticeModal
											}
											setOpenInitialModal={
												setOpenInitialModal
											}
											practiceData={practiceData}
										/>
									) : selectedContent.content_type == 10 ? (
										<ProgramAudioSection
											data={selectedContent}
											subjectName={subjectName}
											updateContentStatus={
												updateContentStatus
											}
											isNextPractice={isNextPractice}
											setOpenPracticeModal={
												setOpenPracticeModal
											}
											setOpenInitialModal={
												setOpenInitialModal
											}
											practiceData={practiceData}
										/>
									) : selectedContent.content_type == 6 ? (
										<ProgramPdf
											data={selectedContent}
											subjectName={subjectName}
											updateContentStatus={
												updateContentStatus
											}
											isNextPractice={isNextPractice}
											setOpenPracticeModal={
												setOpenPracticeModal
											}
											setOpenInitialModal={
												setOpenInitialModal
											}
											practiceData={practiceData}
										/>
									) : selectedContent.content_type == 8 ? (
										<ProgramNoteSection
											data={selectedContent}
											updateContentStatus={
												updateContentStatus
											}
											isNextPractice={isNextPractice}
											setOpenPracticeModal={
												setOpenPracticeModal
											}
											setOpenInitialModal={
												setOpenInitialModal
											}
											practiceData={practiceData}
										/>
									) : null
								) : (
									<EmptyContainer>
										{/* <EmptyPage
                                            img={emptyPic}
                                            message={"Content not added yet"}
                                        /> */}
									</EmptyContainer>
								)
							) : (
								<Loader />
							)}
						</LeftPart>
						{lessons.length > 0 && (
							<RightPart>
								{lessons.map((lesson) => (
									<div key={lesson.id}>
										<LessonCard
											data={lesson}
											selectedLesson={selectedLesson}
											setSelectedLesson={
												setSelectedLesson
											}
											selectedTopic={selectedTopic}
											setSelectedTopic={setSelectedTopic}
											selectedContent={selectedContent}
											setSelectedContent={
												setSelectedContent
											}
											// setSingleContentId={
											// 	setSingleContentId
											// }
											getSingleContent={getSingleContent}
										/>
									</div>
								))}
							</RightPart>
						)}
					</ContentBox>
				</>
			) : (
				<Loader />
			)}
			{isWarningModal && (
				<WarningModalBackContainer>
					<WarningModal>
						<WarningTop>
							<WarningIcon src={warningIcon} alt="icon" />
							<WarningText>WARNING!</WarningText>
						</WarningTop>
						<WarningBottom>
							<WarningPara>
								{/* Any unsaved changes will be lost. */}
								Are you sure you want to exit?
							</WarningPara>
							<ButtonsContainer>
								<WCancelButton
									onClick={() => {
										setIsWarningModal(false);
									}}
								>
									Cancel
								</WCancelButton>
								<WDeleteButton
									onClick={() => {
										handleClosePracticeModal();
										setIsWarningModal(false);
									}}
								>
									Yes
								</WDeleteButton>
							</ButtonsContainer>
						</WarningBottom>
					</WarningModal>
				</WarningModalBackContainer>
			)}
			{isSuccessModal && (
				<WarningModalBackContainer>
					<WarningModal>
						<WarningTop>
							<WarningIcon src={successIcon} alt="icon" />
							<WarningText>Success</WarningText>
						</WarningTop>
						<WarningBottom>
							<WarningPara>
								You have successfully submitted Practice
							</WarningPara>
							<SButtonsContainer>
								<WDeleteButton
									onClick={() => {
										setOpenPracticeModal(false);
										setIsSuccessModal(false);
										updateContentStatus(selectedContent.id);
										// getSingleContent(contentId);
									}}
								>
									Okey
								</WDeleteButton>
							</SButtonsContainer>
						</WarningBottom>
					</WarningModal>
				</WarningModalBackContainer>
			)}
		</MainContainer>
	);
}

const MainContainer = styled.div`
	background: #ffffff;
	padding: 20px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	@media (max-width: 950px) {
		overflow-y: scroll;
	}
`;
const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;
const TextDiv = styled.div``;
const PathText = styled.span`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
const Slash = styled.span`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
`;
const PathTextBlack = styled.span`
	color: #000;
	display: inline-block;
	font-size: 14px;
`;
const DownloadSyllabus = styled.a`
	display: inline-block;
	color: #917afd;
	font-size: 0.9rem;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
const EmptyDiv = styled.div`
	display: none;
	@media (max-width: 950px) {
		display: none;
	}
`;
const ContentBox = styled.div`
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: start;
	@media (max-width: 950px) {
		flex-wrap: wrap;
		/* max-height: calc(100vh - 200px);
        overflow-y: scroll; */
	}
`;
const LeftPart = styled.div`
	width: calc(100% - 415px);
	height: calc(100vh - 200px);
	box-sizing: border-box;
	overflow-y: scroll;
	/* border-radius: 6px; */
	/* &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */
	@media (max-width: 1500px) {
		width: calc(100% - 360px);
	}
	@media (max-width: 1300px) {
		width: calc(100% - 310px);
	}
	@media (max-width: 950px) {
		width: 100%;
		max-height: none;
		overflow-y: visible;
		/* margin-bottom: 40px; */
	}
	/* @media (max-width: 1190px) {
        width: calc(100% - 250px);
    } */
	/* border: 1px solid #e5e5e5; */
`;

const RightPart = styled.div`
	width: 400px;
	/* padding-right: 5px; */
	/* border: 1px solid #ededed; */
	/* border-right: none; */
	/* border-radius: 10px; */
	/* min-height: 480px; */
	max-height: calc(100vh - 200px);
	border-radius: 0 0 10px 10px;
	overflow-y: scroll;
	transition: 0.5s;
	&::-webkit-scrollbar {
		width: 0px;
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: #d6dee1;
		border-radius: 20px;
	}
	@media (max-width: 1500px) {
		width: 350px;
	}
	@media (max-width: 1300px) {
		width: 300px;
	}
	@media (max-width: 950px) {
		/* display: none; */
		/* max-height: auto !important; */
		max-height: none;
		overflow-y: scroll;
		width: 100%;
		margin-top: 30px;
	}
	@media (max-height: 700px) {
		/* display: none; */
		/* max-height: auto !important; */
		max-height: calc(100vh - 220px);
	}
	/* @media (max-width: 1190px) {
        width: 250px;
    } */
`;

const EmptyContainer = styled.div`
	height: calc(100vh - 200px);
	overflow-y: scroll;
`;

const WarningModalBackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1002;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.3);
	cursor: not-allowed;
`;
const WarningModal = styled.div`
	width: 400px;
	/* height: 250px; */
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
	background: #fff;
	position: absolute;
	z-index: 201;
	border-radius: 8px;
	cursor: alias;
`;
const WarningTop = styled.div`
	border-bottom: 1px solid #e7e7e7;
	display: flex;
	align-items: center;
	padding: 20px 15px;
`;
const WarningIcon = styled.img`
	width: 22px;
	height: 20px;
	object-fit: contain;
`;
const WarningText = styled.h6`
	font-size: 1.07rem;
	color: #ff0048;
	text-decoration: uppercase;
	margin-left: 8px;
	letter-spacing: 0.12em;
`;
const WarningBottom = styled.div`
	padding: 40px 15px;
`;
const WarningPara = styled.p`
	color: #000000;
	font-size: 1rem;
	opacity: 0.5;
	width: 60%;
	text-align: center;
	margin: 0 auto;
`;
const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	margin-top: 25px;
`;
const WCancelButton = styled.button`
	padding: 10px 13px;
	color: #522f91;
	font-size: 1rem;
	border: 1px solid #fff;
	transition: 0.5s;
	cursor: pointer;
	border-radius: 8px;
	&:hover {
		border: 1px solid #522f91;
		/* box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em); */
	}
`;
const WDeleteButton = styled.button`
	padding: 10px 13px;
	color: #ffffff;
	font-size: 1rem;
	border: 1px solid #ff0048;
	transition: 0.5s;
	cursor: pointer;
	background: #ff0048;
	border-radius: 8px;
	margin-left: 10px;
	&:hover {
		border: 1px solid #522f91;
		/* box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em); */
	}
`;
const SButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 25px;
`;
