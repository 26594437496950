import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

import Loader from "../loader/Loader";
import EmptyPage from "../emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";

export default function CRAnnouncement() {
	// const [isExpanded, setIsExpanded] = useState(false);
	// Maintain individual isExpanded state for each announcement
	const [expandedAnnouncements, setExpandedAnnouncements] = useState({});
	const { state } = useContext(Context);
	const location = useLocation();
	const [isLoading, setLoading] = useState(true);
	const [announcementList, setAnnouncementList] = useState([]);
	const search_path = location?.search;
	const classroom_id = new URLSearchParams(search_path).get("c");

	// const toggleReadMore = () => {
	// 	setIsExpanded(!isExpanded);
	// };
	const toggleReadMore = (index) => {
		setExpandedAnnouncements((prevExpanded) => ({
			...prevExpanded,
			[index]: !prevExpanded[index],
		}));
	};
	const getAnnouncements = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/class-room-announcements/${classroom_id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setAnnouncementList(data);
					console.log(data, "data is here");
					setExpandedAnnouncements({});
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})

			.catch((error) => {});
	};

	useEffect(() => {
		getAnnouncements();
	}, [classroom_id]);
	return (
		<>
			<AnnouncementContainer>
				<AnnouncementTitleSection>
					<AnnouncementTitle>Announcements</AnnouncementTitle>
				</AnnouncementTitleSection>
				{!isLoading ? (
					<>
						{announcementList?.length > 0 ? (
							<AnnouncementsCards>
								{announcementList.map((data, index) => (
									<Card key={index}>
										<CardTop>
											<CardHeading>{data.title}</CardHeading>
											<Date>{data.date} </Date>
										</CardTop>
										<CardContent>
											{/* try this for read more */}
											{/* {!isExpanded ? data.description.slice(0, 300) : data.description}
											{""}
											{data.description.length > 300 && (
												<ReadMore onClick={toggleReadMore}>
													{isExpanded ? " Read less" : " Read more"}
												</ReadMore>
											)} */}

											{/* try this for read more */}
											{!expandedAnnouncements[index]
												? data.description.slice(0, 300)
												: data.description}
											{data.description.length > 300 && (
												<ReadMore onClick={() => toggleReadMore(index)}>
													{expandedAnnouncements[index] ? " Read less" : " Read more"}
												</ReadMore>
											)}
										</CardContent>
									</Card>
								))}
							</AnnouncementsCards>
						) : (
							<EmptyContainer>
								<EmptyPage img={emptyPic} message={"You have no announcements"} />
							</EmptyContainer>
						)}
					</>
				) : (
					<Loader />
				)}
			</AnnouncementContainer>
		</>
	);
}
const AnnouncementContainer = styled.div``;
const AnnouncementTitleSection = styled.div`
	margin-bottom: 20px;
`;
const AnnouncementTitle = styled.h4`
	color: #010101;
	font-weight: 500;
	font-size: 22px;
	/* line-height: 29px; */
`;
const AnnouncementsCards = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
const Card = styled.li`
	border: 1px solid #e5e5e5;
	border-radius: 20px;
	padding: 24px;
`;
const CardTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	color: #010101;
`;
const CardHeading = styled.h5`
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;
const Date = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
	letter-spacing: 0.2px;
	text-transform: capitalize;
`;
const CardContent = styled.p`
	color: #9d9d9d;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
`;
const ReadMore = styled.span`
	color: #4a96ef;
	font-size: 15px;
	cursor: pointer;
`;
const EmptyContainer = styled.div`
	height: calc(100vh - 350px);
	/* height: 40vh; */
	overflow-y: scroll;
`;
// const AnnouncementsCards = styled.div``;
// const AnnouncementsCards = styled.div``;
