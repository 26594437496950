import React, { useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";
// import videoImage from "../../../../assets/images/video-image.svg";
import nextArrow from "../../../../assets/icons/arrow-right.svg";
import "./../../../../assets/css/style.css";

export default function ProgramVideoSection({
	data,
	subjectName,
	updateContentStatus,
	isNextPractice,
	setOpenPracticeModal,
	setOpenInitialModal,
	practiceData,
}) {
	const videoRef = useRef();

	const attemptPlay = () => {
		// videoRef &&
		videoRef.current.props.onPlay();
		// videoRef.current &&
		// 	videoRef.current.play().catch((error) => {
		// 	});
	};

	const focusToDiv = () => {
		// videoRef.current.focus();
		// attemptPlay();

		document.querySelector("#vimeo-player-new-id").focus();
	};

	useEffect(() => {
		// attemptPlay();
	}, []);

	return (
		<LeftContentsContainer>
			<MediaContainer>
				<ReactPlayer
					id="media-container"
					ref={videoRef}
					className="vid-player"
					url={data.content.video_link}
					// onReady={focusToDiv}
					// autoplay={true}
					// playing={true} //-------
					// ref={videoRef}
					// onReady={handleDiv}
					// url={`https://vimeo.com/636287579`}
					// url="https://vimeo.com/186191787"
					// playing={true}
					controls={true}
					// light={true}
					width="100%"
					// height="100%"
					config={{
						youtube: {
							playerVars: {
								autoplay: 1,
								// controls: 1,
								rel: 0,
								// controls: 0,
								modestbranding: 1,
								autoplay: 1,
								loop: 1,
								// end: alert("Completed"),

								// iv_load_policy: 3,
							},
						},
						vimeo: {
							playerVars: {
								controls: false,
							},
							playerOptions: {
								// autoplay: true,
								// id: "vimeo-player-new-id",
							},
							id: "vimeo-player-new-id",
						},
					}}
				/>
				{/* <Vimeo
                    video={data.content.video_link}
                    className="vid-player"
                    width="100%"
                    height="100%"
                    // title="false"
                /> */}
				{/* <ControlsWrapper>
                    <TopPart></TopPart>
                    <MiddlePart></MiddlePart>
                    <BottomPart></BottomPart>
                </ControlsWrapper> */}
			</MediaContainer>
			<DescriptionContainer>
				<TopSection>
					<TopUp>
						<TitleText>{data.content.title}</TitleText>
					</TopUp>
					<TopDown>
						<CourseText>Lesson: {data.lesson_name}</CourseText>
						{data.status !== "completed" && (
							<NextTopicButton
								// onClick={() => updateContentStatus(data.id)}
								onClick={() => {
									!isNextPractice &&
										updateContentStatus(data.id);

									isNextPractice && practiceData.length > 0
										? setOpenPracticeModal(true)
										: updateContentStatus(data.id);
								}}
							>
								<NextTopicText>Mark as completed</NextTopicText>
								{/* <NextIcon src={nextArrow} /> */}
							</NextTopicButton>
						)}
					</TopDown>
				</TopSection>
				<AboutSubjectText>Description</AboutSubjectText>
				<AboutSubjectDetailedContainer>
					<AboutSectionDetailedText>
						{data.content.description}
					</AboutSectionDetailedText>
				</AboutSubjectDetailedContainer>
			</DescriptionContainer>
		</LeftContentsContainer>
	);
}

const LeftContentsContainer = styled.div`
	padding-right: 5px;
`;
const MediaContainer = styled.div`
	width: 100%;
	position: relative;
	/* border-radius: 10px; */
	margin-bottom: 25px;
	.vid-player {
		height: auto !important;
		aspect-ratio: 16/9 !important;
		/* border-radius: 10px; */
	}
	&.player .vp-controls .logo {
		display: none !important;
	}
`;
const ControlsWrapper = styled.p`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 1;
`;
const TopPart = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const MiddlePart = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const BottomPart = styled.div``;

const DescriptionContainer = styled.div``;
const TopSection = styled.div`
	margin-bottom: 15px;
`;
const TopUp = styled.div`
	margin-bottom: 5px;
`;
const TitleText = styled.h5`
	font-size: 1.3rem;
	font-weight: 500;
	text-transform: capitalize;
	color: #000;
`;
const CourseText = styled.p`
	color: #c1c1c1;
	text-transform: capitalize;
	font-size: 1rem;
`;
const TopDown = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;
const NextTopicButton = styled.button`
	background: #15bf81;
	display: inline-flex;
	align-items: center;
	border-radius: 6px;
	padding: 6px 14px;
	cursor: pointer;
`;
const NextTopicText = styled.p`
	margin-right: 5px;
	font-size: 0.9rem;
`;
const NextIcon = styled.img`
	width: 20px;
	object-fit: cover;
`;
const AboutSubjectText = styled.div`
	font-size: 1.1rem;
	font-weight: 500;
	color: #404040;
	text-transform: capitalize;
	margin-bottom: 15px;
`;
const AboutSubjectDetailedContainer = styled.div`
	@media (max-width: 1500px) {
		width: 98%;
	}
`;
const AboutSectionDetailedText = styled.p`
	color: #9d9d9d;
	font-size: 0.9rem;
	/* width: 99%; */
`;
