import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import propic from "../../../assets/images/profil.png";
import ClassroomCardSingle from "./ClassroomCardSingle";
import listicon from "../../../assets/icons/list-icon.svg";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";

import LiveClassCard from "../../includes/liveClasses/LiveClassCard";
import VideoModal from "../../includes/VideoModal";
import Loader from "../../includes/loader/Loader";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import searchIcon from "../../../assets/icons/finance-search-icon.svg";
import closeIcon from "../../../assets/icons/rounded-close-icon.svg";

import EmptyPage from "../../includes/emptyPages/EmptyPage";
import UpcomingLiveCard from "./UpcomingLiveCard";
import CRLive from "../../includes/classroom/CRLive";
import CRAssignment from "../../includes/classroom/CRAssignment";
import CRClassmate from "../../includes/classroom/CRClassmate";
import CRFeedback from "../../includes/classroom/CRFeedback";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "@material-ui/core";
import CRAnnouncement from "../../includes/classroom/CRAnnouncement";
import CRModulekit from "../../includes/classroom/CRModulekit";

export default function ClassroomSingle() {
	const [selectedProgramStatus, setSelectedProgramStatus] = useState("live-class");
	// const [active, setActive] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [isSingleLoading, setSingleLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [classroomList, setClassroomList] = useState({});
	const [selectedClassRoomID, setSelectedClassRoomID] = useState("");
	const { state } = useContext(Context);
	const history = useHistory();
	const location = useLocation();
	const [completedVideoLink, setCompletedVideoLink] = useState("");
	const [selectedClassroomDetails, setselectedClassroomDetails] = useState({});

	//  for mui modal
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);

	const getClassrooms = () => {
		// setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/class-rooms/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					search: search,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setClassroomList(data);
					if (data.data.length > 0) {
						history.push(`?c=${data.data[0].id}`);
					}
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})

			.catch((error) => {});
	};

	const search_path = location?.search;
	const classroom_id = new URLSearchParams(search_path).get("c");

	const getSingleClassroom = () => {
		setSingleLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/class-room/${classroom_id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					if (data) {
						setselectedClassroomDetails(data);
						setTimeout(() => {
							setSingleLoading(false);
						}, 200);
					}
				} else {
					setTimeout(() => {
						setSingleLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})

			.catch((error) => {});
	};

	useEffect(() => {
		getClassrooms();
	}, [search]);

	useEffect(() => {
		if (classroom_id) {
			getSingleClassroom();
		}
	}, [classroom_id]);

	// For Modal
	const [isVideoModal, setIsVideoModal] = useState(false);
	const [isVideoLoading, setIsVideoLoading] = useState(false);

	const handleOpenVideoModal = () => {
		setIsVideoModal(true);
	};
	const handleCloseVideoModal = () => {
		setIsVideoModal(false);
	};

	const tabCategories = [
		{
			id: 1,
			title: "Live Class",
			value: "live-class",
		},
		{
			id: 2,
			title: "Assignments",
			value: "assignments",
		},
		{
			id: 3,
			title: "Module kit",
			value: "modulekit",
		},
		{
			id: 4,
			title: "Announcements",
			value: "announcements",
		},
		{
			id: 5,
			title: "Classmates",
			value: "classmates",
		},
		// {
		//     id: 4,
		//     title: "Feedbacks",
		//     value: "feedbacks",
		// },
	];
	return (
		<>
			{!isLoading ? (
				<MainContainer>
					{Object.keys(selectedClassroomDetails).length !== 0 ? (
						<>
							<ClassroomContainer>
								<CardContainer>
									<Titles>
										<SmallTitle>Classroom</SmallTitle>
										<BigTitle>{classroomList.program_name}</BigTitle>
									</Titles>
									{/* <ClickList>
										<ListIcon src={listicon} alt="Icon" />
									</ClickList> */}

									<ClassSections>
										<SearchBoxDiv>
											<label htmlFor="classroom-search">
												<SearchIcon src={searchIcon} alt="icon" />
											</label>
											<SearchInput
												id="classroom-search"
												type="text"
												placeholder="Search"
												value={search}
												onChange={(e) => setSearch(e.target.value)}
											/>
										</SearchBoxDiv>
										{classroomList.data.length > 0 ? (
											<ClassroomCardSingle
												data={classroomList.data}
												// selectedClassRoomID={
												// 	selectedClassRoomID
												// }
												// setSelectedClassRoomID={
												// 	setSelectedClassRoomID
												// }
												classroom_id={classroom_id}
											/>
										) : (
											<div style={{ padding: "0px 15px" }}>
												<EmptyPage img={emptyPic} message={"You have no classrooms"} />
											</div>
										)}
									</ClassSections>
								</CardContainer>

								{!isSingleLoading ? (
									<VideoConatiner>
										<TopSection>
											<Title>
												<IdTitle>
													Room ID :<IdText> {selectedClassroomDetails.data.room_no}</IdText>
												</IdTitle>
												<SubTitle>{selectedClassroomDetails?.data.subject_name}</SubTitle>
											</Title>
											<ProSection
												onClick={() => {
													handleOpenModal();
												}}>
												<ProImg
													src={selectedClassroomDetails?.data.faculty_image}
													alt="Image"
												/>
												<ProDetailes>
													<Facualty>Faculty</Facualty>
													<StudentName>
														{selectedClassroomDetails.data.faculty_name}
													</StudentName>
												</ProDetailes>
											</ProSection>
										</TopSection>
										<Borderline></Borderline>
										<BottomSection>
											<TabSection>
												<ProgramButtonDiv>
													{tabCategories.map((data, i) => (
														<>
															<ProgramButton
																background={
																	selectedProgramStatus === data.value
																		? "#fff"
																		: "#F5F5F5"
																}
																boxShadow={
																	selectedProgramStatus === data.value
																		? "0px 6px 20px rgba(0, 0, 0, 0.16);"
																		: "none"
																}
																onClick={() => {
																	setSelectedProgramStatus(data.value);
																}}>
																<ButtonName
																	color={
																		selectedProgramStatus === data.value
																			? "#404040"
																			: "#404040"
																	}>
																	{data.title}
																</ButtonName>
															</ProgramButton>
															{i + 1 < tabCategories.length && <Border>|</Border>}
														</>
													))}
												</ProgramButtonDiv>
											</TabSection>

											{selectedProgramStatus === "live-class" ? (
												<CRLive
													isSingleLoading={isSingleLoading}
													data={selectedClassroomDetails}
												/>
											) : selectedProgramStatus === "assignments" ? (
												<CRAssignment
													data={selectedClassroomDetails}
													refreshApi={getSingleClassroom}
												/>
											) : selectedProgramStatus === "classmates" ? (
												<CRClassmate />
											) : selectedProgramStatus === "announcements" ? (
												<CRAnnouncement />
											) : selectedProgramStatus === "modulekit" ? (
												<CRModulekit />
											) : selectedProgramStatus === "feedbacks" ? (
												<CRFeedback />
											) : null}
										</BottomSection>
									</VideoConatiner>
								) : (
									<Loader />
								)}
							</ClassroomContainer>
						</>
					) : (
						<EmptyPage img={emptyPic} message={"You have no classrooms"} />
					)}
				</MainContainer>
			) : (
				<LoadinContainer>
					<Loader />
				</LoadinContainer>
			)}
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<FacultyModalContainer>
					{openModal && (
						<>
							<ModalLeft>
								<FacultyImage src={selectedClassroomDetails?.data.faculty_image} alt="image" />
								<FacultyName>{selectedClassroomDetails?.data.faculty_name}</FacultyName>
							</ModalLeft>
							<ModalRight>
								{/* <ModalRightContent> */}
								<AboutMe>About Me</AboutMe>
								<FacultyBio>{selectedClassroomDetails?.data.faculty_bio}</FacultyBio>
								<CloseIcon
									src={closeIcon}
									alt="icon"
									onClick={() => {
										handleCloseModal();
									}}
								/>
								{/* </ModalRightContent> */}
							</ModalRight>
						</>
					)}
				</FacultyModalContainer>
			</Modal>
		</>
	);
}

const MainContainer = styled.div`
	background: #ffffff;
	padding: 20px 15px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
`;
const ClassroomContainer = styled.div`
	display: grid;
	grid-gap: 32px;
	grid-template-columns: 1fr 3fr;
	/* width: 382px; */
	/* background: #F5F5F5; */
	/* border-radius: 12px; */
`;
const Titles = styled.div`
	padding: 20px 20px 16px 16px;
`;
const SmallTitle = styled.h3`
	color: #9d9d9d;
	font-size: 13px;
	font-weight: 500;
`;
const BigTitle = styled.h4`
	margin-top: 4px;
	/* font-weight: 600; */
	color: #000000;
	font-size: 20px;
	font-family: "product_sansbold";
	line-height: 24px;
`;
const ClickList = styled.div`
	position: absolute;
	right: -15px;
	top: 60px;
	border: 1px solid #f4f4f4;
	border-radius: 80px;
	background: #ffff;
	width: 32px;
	height: 32px;
	padding: 7px;
`;
const ListIcon = styled.img`
	display: block;
	width: 100%;
	height: 100%;
`;

const CardContainer = styled.div`
	/* width: 382px; */
	background: #f5f5f5;
	border-radius: 12px;
	position: relative;
	height: calc(100vh - 152px);
	overflow-y: scroll;
`;

const ClassSections = styled.div`
	/* background: #F5F5F5; */
	padding: 0 20px 16px 16px;
`;
const Borderline = styled.div`
	border-bottom: 2px solid #f5f5f5;
	margin-top: 6px;
`;

const VideoConatiner = styled.div`
	position: relative;
	::before {
		position: absolute;
		content: "";
		width: 1px;
		height: 600px;
		left: -15px;
		top: 5px;
		border-left: 1px solid #e7e7e7;
	}
`;
const BottomSection = styled.div`
	height: calc(100vh - 220px);
	overflow-y: scroll;
`;

const TopSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Title = styled.div``;

const IdTitle = styled.h4`
	font-size: 14px;
	color: #9d9d9d;
`;
const IdText = styled.span`
	font-size: 14px;
	font-weight: 500;
	color: #9d9d9d;
`;
const SubTitle = styled.h4`
	color: #010101;
	font-weight: 600;
	font-size: 18px;
	margin-top: 4px;
	letter-spacing: 0.5px;
`;
const ProSection = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const ProImg = styled.img`
	display: block;
	width: 44px;
	height: 44px;
	border-radius: 50%;
`;
const ProDetailes = styled.div`
	margin-left: 10px;
`;
const Facualty = styled.span`
	color: #9d9d9d;
	font-weight: 500;
	font-size: 14px;
`;
const StudentName = styled.h5`
	margin-top: 4px;
	color: #000000;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.5px;
`;
const TabSection = styled.div`
	width: max-content;
	margin: 26px auto;
`;
const ProgramButtonDiv = styled.div`
	display: flex;
	align-items: center;
	background: #f5f5f5;
	height: 44px;
	box-sizing: border-box;
	border: 1px solid #e7e7e7;

	padding: 4px;
	border-radius: 10px;
`;
const ProgramButton = styled.div`
	background-color: ${(props) => (props.background ? props.background : "#F5F5F5")};
	padding: 8px 8px;
	min-width: 100px;
	/* width: fit-content; */
	box-sizing: border-box;
	height: 36px;
	border-radius: 8px;
	cursor: pointer;
	box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const Border = styled.span`
	font-size: 1rem;
	color: #e7e7e7;
	display: inline-block;
	margin: 0 10px;
`;
const ButtonName = styled.h3`
	color: ${(props) => (props.color ? props.color : "#9D9D9D")};
	font-size: 0.9rem;
	font-weight: 500;
	/* font-family: "poppinssemibold"; */
	font-family: "inter-regular";

	padding: 0 10px;
	text-align: center;
	/* font-weight: 500; */
	/* font-size: 14px; */
`;
const VideoSection = styled.div``;
const ContentSection = styled.div`
	margin-top: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	/* margin-top: 20px; */
	/* display: grid; */
	/* grid-template-columns: 1fr 1fr ; */
	/* border: 1px solid #e5e5e5; */
	/* height: calc(100vh - 260px); */
	overflow-y: scroll;
	grid-gap: 20px;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 0px;
		display: none;
	}
	&::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}
	@media (max-width: 1500px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 880px) {
		grid-template-columns: 1fr;
	}
`;
const UpcomingDiv = styled.div``;
// const UpcomingDiv = styled.div`
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// `;

const EmptyContainer = styled.div`
	height: calc(100vh - 295px);
	overflow-y: scroll;
	/* ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
    } */
`;
// const VideoModalBackContainer = styled.div`
// 	/* position: fixed;
// 	transition: 0.3s;
// 	width: 100%;
// 	height: 100vh;
// 	z-index: 1002;
// 	left: 0;
// 	top: 0px;
// 	background: rgba(0, 0, 0, 0.3); */

// 	/* cursor: not-allowed; */
// `;
// const CenderDiv = styled.div`
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// `;
const LiveSection = styled.div``;
const LivetitleSection = styled.div``;
const Livetitle = styled.h4`
	color: #010101;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	/* margin-top: 30px; */
`;

const ClassroomSection = styled.div``;
const CompleteDiv = styled.div`
	margin-top: 16px;
`;

const CompleteSection = styled.div``;
const CompleteTitle = styled.h4`
	margin-bottom: 8px;
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
`;
const LoadinContainer = styled.div`
	min-height: calc(100vh - 105px);
	display: flex;
	align-items: center;
	justify-content: center;
`;
const SearchBoxDiv = styled.div`
	/* width: calc(100% - 20px); */
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding: 10px 8px;
	height: 40px;
	/* background: #f5f5f5; */
	background: #fff;
	border-radius: 8px;
	/* border: 1px solid #f5f5f5; */
	border: 1px solid #d7d7d7;
	margin-bottom: 20px;

	input[type="text"] {
		border: none; /* Remove default borders */
		padding: 4px 8px;
		border-radius: 12px; /* Sadly outline does not round! therefore...*/
	}
	input[type="text"]:focus {
		background: #fff;
		outline: none; /* Remove default outline and use border or box-shadow */
		/* box-shadow: 0 0 0 2px orange; */
		/* Full freedom. (works also with border-radius) */
	}
`;
const SearchIcon = styled.img`
	width: 18px;
	display: block;
	object-fit: cover;
	margin-right: 0px;
	cursor: pointer;
`;
const SearchInput = styled.input`
	display: inline-block;
	/* width: calc(210px - 18px); */
	width: 100%;
	height: 34px;
	box-sizing: border-box;
	/* padding: 5px 0; */
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	border: none;
	color: #404040;

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #b6b6b6;
		opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #b6b6b6;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #b6b6b6;
	}
`;
// const FacultyModalContainer = styled.div`
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     max-height: 85vh;
//     box-sizing: border-box;
//     width: 900px;
//     display: flex;
//     align-items: flex-start;
//     background: #ffffff;
//     border-radius: 16px !important;
//     height: fit-content;

// `;
// const ModalLeft = styled.div`
//     background: linear-gradient(180deg, #ea1752 0%, #522f91 100%);
//     padding: 40px;
//     width: 50%;
//     height: 100%;
//     border-radius: 16px 0px 0px 16px;
// `;
// const ModalRight = styled.div`
//     width: 50%;
//     background: #fff;
//     padding: 40px;
//     height: 100%;

// `;

const FacultyModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 85vh;
	box-sizing: border-box;
	width: 900px;
	display: flex;
	align-items: stretch;
	background: #ffffff;
	border-radius: 16px !important;
`;

const ModalLeft = styled.div`
	background: linear-gradient(180deg, #ea1752 0%, #522f91 100%);
	padding: 40px;
	flex: 1;
	display: flex;
	flex-direction: column;
	border-radius: 16px 0px 0px 16px;
`;

const ModalRight = styled.div`
	width: 50%;
	background: #fff;
	padding: 40px;
	/* height: 100%; */
	overflow-y: scroll;
	border-radius: 0px 16px 16px 0px;
	position: relative;
`;

const ModalRightContent = styled.div``;

const FacultyImage = styled.img`
	width: 150px;
	height: 150px;
	border-radius: 50%;
	object-fit: cover;
	display: block;
`;
const FacultyName = styled.h6`
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	margin-top: 10px;
	/* identical to box height */

	text-transform: capitalize;

	/* Light/500 white */

	color: #ffffff;
`;
const AboutMe = styled.h6`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-transform: capitalize;

	/* Dark/300 */

	color: #9d9d9d;
`;
const FacultyBio = styled.p`
	font-weight: 500;
	font-size: 16px;
	margin-top: 16px;
	white-space: pre-wrap;
	/* font-family: "Inter"; */

	/* Dark/400 */

	color: #404040;
`;
const CloseIcon = styled.img`
	position: fixed;
	right: 12px;
	top: 12px;
	cursor: pointer;
	width: 22px;
	display: block;
`;
