import React from "react";
import styled from "styled-components";
import nextArrow from "../../../../assets/icons/arrow-right.svg";
import ParaImage from "../../../../assets/images/para-image.svg";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function ProgramNoteSection({
    data,
    updateContentStatus,
    isNextPractice,
    setOpenPracticeModal,
    setOpenInitialModal,
    practiceData,
}) {
    return (
        <LeftContentsContainer>
            <CkDiv className="ckeditor-custom">
                <CKEditor
                    disabled
                    config={{
                        // extraPlugins: [uploadPlugin],
                        toolbar: [
                            // "heading",
                            // "|",
                            // "|",
                            // "bold",
                            // "italic",
                            // "|",
                            // "bulletedList",
                            // "numberedList",
                            // "|",
                            // "insertTable",
                            // "|",
                            // "outdent",
                            // "indent",
                            // "|",
                            // "uploadImage",
                            // "blockQuote",
                            // "|",
                            // "undo",
                            // "redo",
                        ],
                        shouldNotGroupWhenFull: true,

                        // heading: {
                        // 	options: [
                        // 		{
                        // 			model: "paragraph",
                        // 			title: "Paragraph",
                        // 			class: "ck-heading_paragraph",
                        // 		},
                        // 		{
                        // 			model: "heading1",
                        // 			view: "h3",
                        // 			title: "Heading 1",
                        // 			class: "ck-heading_heading1",
                        // 		},
                        // 		{
                        // 			model: "heading2",
                        // 			view: "h4",
                        // 			title: "Heading 2",
                        // 			class: "ck-heading_heading2",
                        // 		},
                        // 	],
                        // },
                    }}
                    editor={ClassicEditor}
                    data={data?.content?.article_content}
                    onReady={(editor) => {
                        // editor.ui.view.editable.element.style.minHeight = "500px";
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                    onChange={(event, editor) => {
                        // handleChange(editor.getData());
                    }}
                    // {...props}
                />
            </CkDiv>
            {/* <TopSection>
				<TopUp>
					<TitleText>Ui/Ux Differents in design elements</TitleText>
				</TopUp>
				<TopDown>
					<CourseText>Course: design of elements</CourseText>
					<NextTopicButton>
						<NextTopicText>Next Topic</NextTopicText>
						<NextIcon src={nextArrow} />
					</NextTopicButton>
				</TopDown>
			</TopSection>
			<DescriptionContainer>
				<SectionTitleText>
					Consequat ut adipiscing tincidunt sapien.
				</SectionTitleText>
				<ParaText>
					Eu cursus sit arcu libero interdum risus pellentesque. Hac
					amet auctor amet, proin est velit nunc. Duis cum duis in
					libero in diam. Volutpat id lobortis in nec in leo. Viverra
					hac vel pellentesque massa sed nec, ut sed erat. Elit nibh
					in morbi convallis lacus, in suspendisse eu vivamus. In
					vestibulum blandit egestas volutpat gravida. Massa quam
					bibendum vitae nunc adipiscing. Nunc non egestas nibh vitae
					sed sit. Commodo rhoncus libero commodo non. In dignissim
					purus et elit amet. Urna, euismod aliquet habitant mi
					vulputate interdum pellentesque aliquam. Diam.
				</ParaText>
				<ImageContainer>
					<Image src={ParaImage} alt="image" />
				</ImageContainer>
			</DescriptionContainer>
			<DescriptionContainer>
				<SectionTitleText>
					Consequat ut adipiscing tincidunt sapien.
				</SectionTitleText>
				<ParaText>
					Eu cursus sit arcu libero interdum risus pellentesque. Hac
					amet auctor amet, proin est velit nunc. Duis cum duis in
					libero in diam. Volutpat id lobortis in nec in leo. Viverra
					hac vel pellentesque massa sed nec, ut sed erat. Elit nibh
					in morbi convallis lacus, in suspendisse eu vivamus. In
					vestibulum blandit egestas volutpat gravida. Massa quam
					bibendum vitae nunc adipiscing. Nunc non egestas nibh vitae
					sed sit. Commodo rhoncus libero commodo non. In dignissim
					purus et elit amet. Urna, euismod aliquet habitant mi
					vulputate interdum pellentesque aliquam. Diam.
				</ParaText>
				<ImageContainer>
					<Image src={ParaImage} alt="image" />
				</ImageContainer>
			</DescriptionContainer>
			<ButtonContainer>
				<NextTopicButton>
					<NextTopicText>Next Topic</NextTopicText>
					<NextIcon src={nextArrow} />
				</NextTopicButton>
			</ButtonContainer> */}

            {/* <TopDown>
				<CourseText>Lesson: {data.lesson_name}</CourseText>
				{data.status !== "completed" && (
					<NextTopicButton
						onClick={() => updateContentStatus(data.id)}
					>
						<NextTopicText>Mark as completed</NextTopicText>
						<NextIcon src={nextArrow} />
					</NextTopicButton>
				)}
			</TopDown> */}

            <DescriptionContainer>
                <TopSection>
                    <TopUp>
                        <TitleText>{data.content.title}</TitleText>
                    </TopUp>
                    <TopDown>
                        <CourseText>Lesson: {data.lesson_name}</CourseText>
                        {data.status !== "completed" && (
                            <NextTopicButton
                                // onClick={() => updateContentStatus(data.id)}
                                onClick={() => {
                                    !isNextPractice &&
                                        updateContentStatus(data.id);

                                    isNextPractice && practiceData.length > 0
                                        ? setOpenPracticeModal(true)
                                        : updateContentStatus(data.id);
                                }}
                            >
                                <NextTopicText>Mark as completed</NextTopicText>
                                {/* <NextIcon src={nextArrow} /> */}
                            </NextTopicButton>
                        )}
                    </TopDown>
                </TopSection>
                <AboutSubjectText>Description</AboutSubjectText>
                <AboutSubjectDetailedContainer>
                    <AboutSectionDetailedText>
                        {data.content.description}
                    </AboutSectionDetailedText>
                </AboutSubjectDetailedContainer>
            </DescriptionContainer>
        </LeftContentsContainer>
    );
}

const LeftContentsContainer = styled.div`
    padding-right: 5px;
`;
const CkDiv = styled.div`
    &.ckeditor-custom p {
        color: #000;
    }
`;
const TopSection = styled.div`
    margin-bottom: 15px;
`;
const TopUp = styled.div`
    margin-bottom: 5px;
`;
const TitleText = styled.h5`
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #000;
`;
const CourseText = styled.p`
    color: #c1c1c1;
    text-transform: capitalize;
    font-size: 1rem;
`;
const TopDown = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const NextTopicButton = styled.button`
    background: #15bf81;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    padding: 6px 14px;
    cursor: pointer;
`;
const NextTopicText = styled.p`
    margin-right: 5px;
    font-size: 0.9rem;
`;

const NextIcon = styled.img`
    width: 20px;
    object-fit: cover;
`;
const DescriptionContainer = styled.div`
    margin-top: 20px;
`;
const SectionTitleText = styled.span`
    display: inline-block;
    font-size: 1.1rem;
    color: #000000;
    font-weight: 500;
`;
const ParaText = styled.p`
    margin-top: 10px;
    font-size: 0.93rem;
    color: #9d9d9d;
    width: 98%;
`;
const ImageContainer = styled.div`
    margin-top: 10px;
    max-width: 400px;
    max-height: 300px;
`;
const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 15px;
`;
// const NextIcon = styled.img``;

const AboutSubjectText = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
    color: #404040;
    text-transform: capitalize;
    margin-bottom: 15px;
`;
const AboutSubjectDetailedContainer = styled.div`
    @media (max-width: 1500px) {
        width: 98%;
    }
`;
const AboutSectionDetailedText = styled.p`
    color: #9d9d9d;
    font-size: 0.9rem;
    /* width: 99%; */
`;
