import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import Loader from "../../includes/loader/Loader";
import profileIcon from "../../../assets/icons/profile-icon.svg";
import calendarIcon from "../../../assets/icons/calendar-icon.svg";
import attachIcon from "../../../assets/icons/attach-icon.svg";
import moment from "moment";

import docIcon from "../../../assets/icons/doc-icon.svg";
import jpgIcon from "../../../assets/icons/jpg-icon.svg";
import pdfIcon from "../../../assets/icons/pdf-icon.svg";
import pngIcon from "../../../assets/icons/png-icon.svg";
import pptIcon from "../../../assets/icons/ppt-icon.svg";

import removeIcon from "../../../assets/icons/remove-icon.svg";
import othIcon from "../../../assets/icons/oth.png";
import AssignmentQnCard from "../../includes/assignmentCommonComponents/AssignmentQnCard";
import submitIcon from "../../../assets/icons/status-paid-icon.svg";
import pendingIcon from "../../../assets/icons/status-pending-icon.svg";
import lockIcon from "../../../assets/icons/close.png";
import downloadIcon from "../../../assets/icons/download.svg";

function SubmitAssignment() {
	const { state } = useContext(Context);
	let history = useHistory();
	const [isLoading, setLoading] = useState(true);
	const { id } = useParams();

	const [uploadedFile, setUploadedFile] = useState(null);
	const [fileArray, setFileArray] = useState([]);
	const [assignment, setAssignment] = useState();
	const [assignmentDetails, setAssignmentDetails] = useState();

	//viewmore
	const [selectedViewmore, setSelectedViewmore] = useState("");

	const getAssignment = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-assignment/${id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setAssignment(data.data);
					setAssignmentDetails(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	// console.log(assignment, "hhh");
	const submitAssignmentFile = () => {
		let accessToken = state.user_details.access_token;
		var formdata = new FormData();
		formdata.append("submitted_file", uploadedFile);

		baseConfig
			.post(`/students/assignment-submit/${assignmentDetails?.assignment_id}/`, formdata, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					// defaultValues();
					// history.push(`/subject/${data.data.id}`);
					history.push("/assignments/");
				} else {
					alert("Contact LMS");
				}
			})
			.catch((error, response) => {
				alert("Contact LMS");
			});
	};

	useEffect(() => {
		getAssignment();
	}, []);

	return id !== false ? (
		<MainContainer>
			{!isLoading ? (
				<>
					{/* <PathText>
						Assignments <PathTextBlack>/ Submit</PathTextBlack>
					</PathText> */}
					<TextDiv>
						<PathText
							onClick={() => {
								history.push("/assignments/");
							}}>
							Assignments
						</PathText>
						<Slash>&nbsp;/&nbsp;</Slash>
						<PathTextBlack>{assignmentDetails.subject_name}</PathTextBlack>
					</TextDiv>
					<AssignmentTitleText>{assignment?.title}</AssignmentTitleText>
					<div
						style={{
							marginLeft: "20px",
							display: "flex",
							alignItems: "center",
							gap: "40px",
						}}>
						<TotalQuestions>Total Questions: {assignment?.total_questions}</TotalQuestions>
						<TotalQuestions>Total Marks: {assignment?.total_mark}</TotalQuestions>
						<TotalQuestions>Deadline: {moment(assignment?.deadline).format("DD-MM-YYYY")}</TotalQuestions>
						<StatusButton>
							{/* {data?.assignment?.status === '' */}
							<StatusIcon
								src={
									assignmentDetails?.status === "Active"
										? pendingIcon
										: assignmentDetails?.status === "Submitted"
										? submitIcon
										: assignmentDetails?.status === "Closed"
										? lockIcon
										: null
								}
								alt="icon"
							/>
							<StatusText>{assignmentDetails?.status}</StatusText>
						</StatusButton>
						{/* <DownloadQuestionButton>Download</DownloadQuestionButton> */}
						{/* <DownloadQuestionButton>
							<Icon src={downloadIcon} alt="Icon" />
							Download
						</DownloadQuestionButton> */}
					</div>
					{/*
            <UpdatedDetails>
                <ImageTag src={profileIcon} alt="icon" />
                <TextTag>By Emarald Sherin</TextTag>
            </UpdatedDetails>
            <UpdatedDetails>
                <ImageTag src={calendarIcon} alt="icon" />
                <TextTag>Due On : 24/12/2021</TextTag>
            </UpdatedDetails> */}
					<ScrollContainer>
						<InstructionsText>Instructions</InstructionsText>
						<InstructionDiv>
							{assignment.instructions.map((assignment, ind) => (
								<InstructionList key={ind}>{assignment}</InstructionList>
							))}
							{/* <DetailedInstructions>{assignment?.instruction}</DetailedInstructions> */}
						</InstructionDiv>
						<QuestionsText>{assignment?.questions.length > 1 ? "Questions" : "Question"}</QuestionsText>
						{assignment?.questions.length > 0 && (
							<QuestionsWrapper>
								{assignment.questions.map((question, index) => (
									<AssignmentQnCard
										data={question}
										key={index}
										index={index}
										selectedViewmore={selectedViewmore}
										setSelectedViewmore={setSelectedViewmore}
									/>
								))}
							</QuestionsWrapper>
						)}
						{assignmentDetails.can_submit && (
							<SubmissionSection>
								{uploadedFile === null ? (
									<>
										<SubmissionText>SUBMIT ASSIGNMENT</SubmissionText>
										<SubmittingContainer htmlFor="file1">
											<UploadText>Upload your file here</UploadText>
											<UploadButton
											// htmlFor="file1"
											>
												<UploadIcon src={attachIcon} alt="icon" />
												<UploadButtonText>Upload</UploadButtonText>
												<input
													name="myImage"
													// accept="application/pdf"
													accept=".pdf, .doc, .docx, .ppt, .pptx"
													type="file"
													id="file1"
													style={{ display: "none" }}
													// multiple
													onChange={(e) => {
														setUploadedFile(e.target.files[0]);
													}}
												/>
											</UploadButton>
										</SubmittingContainer>
									</>
								) : (
									<>
										<SubmissionText>UPLOADED FILE</SubmissionText>
										{uploadedFile !== null && (
											<SubmittedContainer>
												<FileContainer>
													<FileContainerLeft>
														<FileIcon
															src={
																uploadedFile.type.split("/")[1] ===
																"vnd.openxmlformats-officedocument.wordprocessingml.document"
																	? docIcon
																	: uploadedFile.type.split("/")[1] === "jpeg"
																	? jpgIcon
																	: uploadedFile.type.split("/")[1] === "png"
																	? pngIcon
																	: uploadedFile.type.split("/")[1] === "pdf"
																	? pdfIcon
																	: uploadedFile.type ===
																	  "application/vnd.ms-powerpoint"
																	? pptIcon // Icon for .ppt files
																	: uploadedFile.type ===
																	  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
																	? pptIcon // Icon for .pptx files
																	: uploadedFile.type === "application/msword"
																	? docIcon // Icon for .doc files
																	: uploadedFile.type ===
																	  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
																	? docIcon
																	: othIcon
															}
															alt="icon"
														/>
														<FileName>{uploadedFile?.name}</FileName>
													</FileContainerLeft>
													<FileContainerRight>
														<RemoveIcon
															src={removeIcon}
															alt="icon"
															onClick={() => setUploadedFile(null)}
														/>
													</FileContainerRight>
												</FileContainer>
											</SubmittedContainer>
										)}
										<SubmitButtonContainer onClick={() => submitAssignmentFile()}>
											<SubmitButton>Submit</SubmitButton>
										</SubmitButtonContainer>
									</>
								)}
							</SubmissionSection>
						)}
						{assignmentDetails.status === "Submitted" && (
							<DownloadButtonContainer>
								<DownloadButton
									onClick={(event) => {
										window.open(assignmentDetails.submiited_url, "_blank", "noreferrer");
									}}>
									View uploaded File
								</DownloadButton>
							</DownloadButtonContainer>
						)}
					</ScrollContainer>
				</>
			) : (
				<Loader />
			)}
		</MainContainer>
	) : null;
}

export default SubmitAssignment;

const MainContainer = styled.div`
	background: #ffffff;
	padding: 20px 20px 150px 20px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
`;

const TextDiv = styled.div``;
const PathText = styled.span`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
	cursor: pointer;
	margin-bottom: 30px;

	&:hover {
		text-decoration: underline;
	}
`;
const Slash = styled.span`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
`;
const PathTextBlack = styled.span`
	color: #000;
	display: inline-block;
	font-size: 14px;
`;
// const PathText = styled.span`
// 	display: inline-block;
// 	font-size: 14px;
// 	color: #9d9d9d;
// 	margin-bottom: 30px;
// `;
// const PathTextBlack = styled.span`
// 	color: #000;
// `;
const AssignmentTitleText = styled.h6`
	font-size: 17px;
	font-weight: 500;
	color: #010101;
	line-height: 1.5rem;
	/* user-select: none; */
`;
const TotalQuestions = styled.span`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
	margin-top: 5px;
	margin-right: 40px;
	&:last-child {
		margin-right: 0;
	}
`;
const UpdatedDetails = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	margin-top: 10px;
	&:last-child {
		margin-bottom: 0;
	}
`;
const ImageTag = styled.img`
	margin-right: 10px;
	width: 13px;
`;
const TextTag = styled.h6`
	font-size: 13px;
	color: #404040;
	display: flex;
	align-items: center;
`;
const ScrollContainer = styled.div`
	/* max-height: calc(100vh - 295px);
    overflow-y: scroll; */
	/* border: 1px solid #000; */
`;
const InstructionsText = styled.p`
	text-transform: uppercase;
	font-size: 15px;
	color: #9d9d9d;
	margin-top: 10px;
`;
const InstructionDiv = styled.ul`
	padding: 0 20px 0px 0px;
	list-style: inside;
`;
const InstructionList = styled.li`
	font-size: 14px;
	color: #9d9d9d;
	user-select: text !important;
`;
const DetailedInstructions = styled.pre`
	font-family: inherit;
	margin-top: 15px;
	color: #404040;
	font-size: 1rem;
	width: 98%;
	line-height: 1.5em;
	white-space: pre-wrap; /* Since CSS 2.1 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
`;
const SubmissionSection = styled.div`
	margin-top: 30px;
`;
const SubmissionText = styled.p`
	text-transform: uppercase;
	font-size: 16px;
	color: #9d9d9d;
	margin-top: 30px;
	margin-bottom: 20px;
`;
const SubmittingContainer = styled.label`
	border: 1px dashed #9d9d9d;
	box-sizing: border-box;
	border-radius: 10px;
	/* height: 250px; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 0;
	cursor: pointer;
`;
const UploadText = styled.p`
	color: #c1c1c1;
	font-size: 14px;
	margin-bottom: 10px;
`;
const UploadButton = styled.label`
	padding: 12px 10px;
	border-radius: 8px;
	background: #15bf81;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const UploadIcon = styled.img`
	width: 25px;
	height: 20px;
	object-fit: contain;
`;
const UploadButtonText = styled.p`
	margin-left: 8px;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	margin-bottom: -2px;
`;
const SubmittedContainer = styled.div`
	padding: 25px;
	border: 1px solid #9d9d9d;
	box-sizing: border-box;
	margin-right: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* min-height: 150px; */
	/* display: flex; */
	align-items: center;
	border-radius: 10px;
	/* height: calc(100vh - 580px); */
	/* min-height: 100px; */
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 2px;
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: #d6dee1;
		border-radius: 20px;
	}
`;
const FileContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	/* margin-bottom: 15px; */
	&:last-child {
		margin-bottom: 0;
	}
`;
const FileContainerLeft = styled.div`
	display: flex;
	align-items: center;
`;
const FileIcon = styled.img`
	width: 30px;
	height: 30px;
	object-fit: contain;
	margin-right: 8px;
`;
const FileName = styled.h6`
	font-size: 16px;
	color: #404040;
	text-transform: capitalize;
`;
const FileContainerRight = styled.div``;
const RemoveIcon = styled.img`
	width: 17px;
	height: 17px;
	object-fit: contain;
	cursor: pointer;
`;
const SubmitButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20px;
`;
const SubmitButton = styled.button`
	display: inline-block;
	padding: 12px 20px;
	background: #15bf81;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.05rem;
	color: #fff;
	cursor: pointer;
`;
const QuestionsWrapper = styled.div`
	padding: 15px;
	background: #f7f7f7;
	border-radius: 10px;
`;
const QuestionsText = styled.h6`
	color: #404040;
	font-size: 1rem;
	margin-top: 10px;
	padding: 10px 0;
	text-transform: uppercase;
`;
const DownloadButton = styled.button`
	/* background: #917afd; */
	background: rgb(82, 47, 145);
	border-radius: 4px;
	padding: 8px 16px;
	gap: 10px;
	height: 40px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	color: #fff;
	&:hover {
		opacity: 0.96;
	}
`;
const DownloadButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;
const StatusButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	/* width: 90px; */
	height: 32px;
	box-sizing: border-box;
	border: 1px solid #c1c1c1;
	border-radius: 63px;
	padding: 0 8px;
`;
const StatusIcon = styled.img`
	width: 12px;
	display: block;
	object-fit: cover;
	margin-right: 6px;
`;
const StatusText = styled.p`
	color: #404040;
	font-size: 13px;
	margin-bottom: -2px;
`;
// const DownloadQuestionButton = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	/* width: 90px; */
// 	height: 32px;
// 	box-sizing: border-box;
// 	border: 1px solid #c1c1c1;
// 	border-radius: 63px;
// 	padding: 0 12px;
// 	font-size: 13px;
// 	cursor: pointer;
// `;
// const Icon = styled.img`
// 	width: 15px;
// 	object-fit: contain;
// 	margin-right: 5px;
// 	display: block;
// `;
