import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";

export default function QuestionInstruction() {
	const { state } = useContext(Context);
	const [instruction, setInstruction] = useState([]);

	const getExamDetails = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/exams/exam-instructions/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setInstruction(data);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getExamDetails();
	}, []);

	return (
		<MainContainer>
			<QuestionsContainer>
				<TopDiv>
					<SpanContainer>
						<Icon
							src={
								require("../../../assets/icons/warn.png")
									.default
							}
						/>
					</SpanContainer>
					<HeadText>Exam Instructions</HeadText>
				</TopDiv>
				<Mid>
					<MiddleContainer>
						{instruction.map((item, i) => (
							<div key={i}>
								<SubText>
									{i + 1}. {item.title}
								</SubText>
								{item.instructions.map((data, index) => (
									<UnorderedList key={index}>
										<List>{data.instruction}</List>
									</UnorderedList>
								))}
							</div>
						))}
					</MiddleContainer>
				</Mid>
			</QuestionsContainer>
		</MainContainer>
	);
}

const MainContainer = styled.div`
	//   padding: 20px;
	/* display: flex;
	justify-content: center;
	align-items: center; */
	// height: 100vh;
`;
const QuestionsContainer = styled.div`
	border: 1px solid #e5e5e5;
	border-radius: 16px;
	/* width: 70%; */
	background: #fff;
`;
const TopDiv = styled.div`
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: center;
	border-bottom: 1px solid #e5e5e5;
	padding: 15px;
`;
const SpanContainer = styled.span`
	width: 20px;
	margin-right: 10px;
`;
const Icon = styled.img`
	display: block;
	width: 100%;
`;
const HeadText = styled.h3`
	font-size: 18px;
	font-weight: bold;
	font-family: "inter-medium";
`;
const Mid = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
`;
const MiddleContainer = styled.div`
	width: 80%;
	margin-top: 40px;
`;
const SubText = styled.h4`
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: bold;
	font-family: "inter-medium";
`;
const UnorderedList = styled.ul`
	list-style-type: disc;
`;
const List = styled.li`
	margin-bottom: 10px;
	font-family: "inter-regular";
	font-size: 15px;
	line-height: 27px;
`;
