import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../loader/Loader";
import moment from "moment";
import pin from "../../../assets/icons/pin.svg";
import QuestionCard from "./QuestionCard";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import pendingIcon from "../../../assets/icons/status-pending-icon.svg";
import removeIcon from "../../../assets/icons/remove-icon.svg";
import { useLocation, useHistory } from "react-router-dom";

export default function OngoingAssignment({ data, setSinglePage, refreshApi }) {
    const [isLoading, setLoading] = useState(false);
    const { state } = useContext(Context);
    const location = useLocation();
    const history = useHistory();
    const [uploadedFile, setUploadedFile] = useState(null);

    //viewmore
    const [selectedViewmore, setSelectedViewmore] = useState("");

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 100);
    // }, []);

    const search_path = location?.search;
    const classroom_id = new URLSearchParams(search_path).get("c");

    const submitAssignmentFile = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("submitted_file", uploadedFile);

        baseConfig
            .post(
                `/students/assignment-submit/${data.assignment_id}/`,
                formdata,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // history.push("/assignments/");
                    // history.push(`?c=${classroom_id}`);
                    setSinglePage(false);
                    refreshApi();
                } else {
                    alert("message");
                }
            })
            .catch((error, response) => {});
    };

    return (
        <>
            <MainContainer>
                {!isLoading ? (
                    <>
                        {/* <PathText>
						Assignments <PathTextBlack>/ Submit</PathTextBlack>
					</PathText> */}
                        {/* <TextDiv>
							<PathText
								onClick={() => {
									// history.push("/assignments/");
								}}
							>
								Assignments
							</PathText>
							<Slash>&nbsp;/&nbsp;</Slash>
							<PathTextBlack>one</PathTextBlack>
						</TextDiv> */}
                        <AssignmentTitleText>
                            {data.data.title}
                        </AssignmentTitleText>
                        <StatusDiv>
                            <AssignmentDetails>
                                <TotalQuestions>
                                    Total Questions: {data.data.total_questions}
                                </TotalQuestions>
                                <TotalQuestions>
                                    Total Mark: {data.data.total_mark}
                                </TotalQuestions>
                                {data.data.deadline && (
                                    <TotalQuestions>
                                        Deadline:{" "}
                                        {moment(data.data.deadline).format(
                                            "DD-MM-YYYY"
                                        )}
                                    </TotalQuestions>
                                )}
                            </AssignmentDetails>
                            <StatusButton>
                                <StatusIcon src={pendingIcon} alt="icon" />
                                <StatusText>Pending</StatusText>
                            </StatusButton>
                        </StatusDiv>
                        {/*
            <UpdatedDetails>
                <ImageTag src={profile
                    Icon} alt="icon" />
                <TextTag>By Emarald Sherin</TextTag>
            </UpdatedDetails>
            <UpdatedDetails>
                <ImageTag src={calendarIcon} alt="icon" />
                <TextTag>Due On : 24/12/2021</TextTag>
            </UpdatedDetails> */}
                        <ScrollContainer>
                            <InstructionsText>Instructions</InstructionsText>
                            <InstructionDiv>
                                {data.data.instructions.map((item, i) => (
                                    <InstructionText key={i}>
                                        {item}
                                    </InstructionText>
                                ))}
                            </InstructionDiv>
                            <QuestionsText>
                                {/* {assignment?.questions.length > 1
									? "Questions"
									: "Question"} */}
                                Questions
                            </QuestionsText>
                            {/* {assignment?.questions.length > 0 && ( */}
                            <QuestionsWrapper>
                                {data.data.questions.map((item, i) => (
                                    <QuestionCard
                                        item={item}
                                        index={i}
                                        key={i}
                                        selectedViewmore={selectedViewmore}
                                        setSelectedViewmore={
                                            setSelectedViewmore
                                        }
                                    />
                                ))}
                            </QuestionsWrapper>
                            {/* )} */}
                            {data.status === "Active" && (
                                <SubmissionSection>
                                    {uploadedFile === null ? (
                                        <>
                                            <SubmissionText>
                                                SUBMIT ASSIGNMENT
                                            </SubmissionText>
                                            <SubmittingContainer htmlFor="file1">
                                                <UploadText>
                                                    Upload your file here (.pdf)
                                                </UploadText>
                                                <UploadButton
                                                // htmlFor="file1"
                                                >
                                                    {/* <UploadIcon
													src={attachIcon}
													alt="icon"
												/> */}
                                                    <UploadDiv>
                                                        <Icon
                                                            src={pin}
                                                            alt="Icon"
                                                        />
                                                        <UploadButtonText>
                                                            Upload
                                                        </UploadButtonText>
                                                    </UploadDiv>
                                                    <input
                                                        name="myImage"
                                                        accept="application/pdf"
                                                        type="file"
                                                        id="file1"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        // multiple
                                                        onChange={(e) => {
                                                            setUploadedFile(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                        }}
                                                    />
                                                </UploadButton>
                                            </SubmittingContainer>
                                        </>
                                    ) : (
                                        <>
                                            <SubmissionText>
                                                UPLOADED FILE
                                            </SubmissionText>
                                            {uploadedFile !== null && (
                                                <SubmittedContainer>
                                                    <FileContainer>
                                                        <FileContainerLeft>
                                                            {/* <FileIcon
															src={
																uploadedFile.type.split(
																	"/"
																)[1] ===
																"vnd.openxmlformats-officedocument.wordprocessingml.document"
																	? docIcon
																	: uploadedFile.type.split(
																			"/"
																	  )[1] ===
																	  "jpeg"
																	? jpgIcon
																	: uploadedFile.type.split(
																			"/"
																	  )[1] ===
																	  "png"
																	? pngIcon
																	: uploadedFile.type.split(
																			"/"
																	  )[1] ===
																	  "pdf"
																	? pdfIcon
																	: othIcon
															}
															alt="icon"
														/> */}
                                                            <FileName>
                                                                {
                                                                    uploadedFile?.name
                                                                }
                                                            </FileName>
                                                        </FileContainerLeft>
                                                        <FileContainerRight>
                                                            <RemoveIcon
                                                                src={removeIcon}
                                                                alt="icon"
                                                                onClick={() => {
                                                                    setUploadedFile(
                                                                        null
                                                                    );
                                                                }}
                                                            />
                                                        </FileContainerRight>
                                                    </FileContainer>
                                                </SubmittedContainer>
                                            )}
                                            <SubmitButtonContainer
                                                onClick={() => {
                                                    submitAssignmentFile();
                                                }}
                                            >
                                                <SubmitButton>
                                                    Submit
                                                </SubmitButton>
                                            </SubmitButtonContainer>
                                        </>
                                    )}
                                </SubmissionSection>
                            )}
                            {data.status === "Submitted" && (
                                <DownloadButtonContainer>
                                    <DownloadButton
                                        onClick={(event) => {
                                            window.open(
                                                data.submiited_url,
                                                "_blank",
                                                "noreferrer"
                                            );
                                        }}
                                    >
                                        View uploaded File
                                    </DownloadButton>
                                </DownloadButtonContainer>
                            )}
                        </ScrollContainer>
                    </>
                ) : (
                    <Loader />
                )}
            </MainContainer>
        </>
    );
}
const MainContainer = styled.div`
    background: #ffffff;
    /* padding: 20px 20px 150px 20px; */
    /* border: 1px solid #e5e5e5; */
    box-sizing: border-box;
    margin-top: 32px;
    /* border-radius: 10px; */
    height: calc(100vh - 110px);
    user-select: text !important;
    /* overflow-y: scroll; */
`;

const TextDiv = styled.div``;
const PathText = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #9d9d9d;
    cursor: pointer;
    margin-bottom: 30px;

    &:hover {
        text-decoration: underline;
    }
`;
const Slash = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #9d9d9d;
`;
const PathTextBlack = styled.span`
    color: #000;
    display: inline-block;
    font-size: 14px;
`;
// const PathText = styled.span`
// 	display: inline-block;
// 	font-size: 14px;
// 	color: #9d9d9d;
// 	margin-bottom: 30px;
// `;
// const PathTextBlack = styled.span`
// 	color: #000;
// `;
const AssignmentTitleText = styled.h6`
    font-size: 24px;
    font-weight: 500;
    color: #010101;
    line-height: 1.5rem;
    user-select: text !important;
`;
const StatusDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
`;
const AssignmentDetails = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 60%;
`;
const TotalQuestions = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #9d9d9d;
    margin-top: 5px;
    /* margin-right: 40px; */
    &:last-child {
        margin-right: 0;
    }
`;
const StatusButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    background: #ffecd9;
    box-sizing: border-box;
    border-radius: 63px;
`;
const StatusIcon = styled.img`
    width: 12px;
    display: block;
    object-fit: cover;
    margin-right: 6px;
`;
const StatusText = styled.p`
    color: #ffa143;
    font-size: 13px;
`;
const UpdatedDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const ImageTag = styled.img`
    margin-right: 10px;
    width: 13px;
`;
const TextTag = styled.h6`
    font-size: 13px;
    color: #404040;
    display: flex;
    align-items: center;
`;
const ScrollContainer = styled.div`
    margin-top: 30px;
    /* max-height: calc(100vh - 295px);
    overflow-y: scroll; */
    /* border: 1px solid #000; */
`;
const InstructionsText = styled.p`
    text-transform: uppercase;
    font-size: 15px;
    color: #9d9d9d;
    margin-top: 10px;
`;
const InstructionDiv = styled.div`
    /* padding: 0 20px; */
    list-style: inside;
    margin-top: 10px;
`;
const InstructionText = styled.p`
    font-size: 16px;
    color: #404040;
`;
const DetailedInstructions = styled.pre`
    font-family: inherit;
    margin-top: 15px;
    color: #404040;
    font-size: 1rem;
    width: 98%;
    line-height: 1.5em;
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
`;
const SubmissionSection = styled.div`
    padding: 30px 0;
`;
const SubmissionText = styled.p`
    text-transform: uppercase;
    font-size: 16px;
    color: #9d9d9d;
`;
const Icon = styled.img`
    display: inline-block;
    width: 14px;
    object-fit: contain;
`;
const SubmittingContainer = styled.label`
    border: 1px dashed #9d9d9d;
    box-sizing: border-box;
    border-radius: 10px;
    /* height: 250px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    cursor: pointer;
`;
const UploadText = styled.p`
    color: #c1c1c1;
    font-size: 14px;
    margin-bottom: 10px;
`;
const UploadButton = styled.label`
    padding: 12px 10px;
    border-radius: 8px;
    background: #15bf81;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const UploadIcon = styled.img`
    width: 25px;
    height: 20px;
    object-fit: contain;
`;
const UploadDiv = styled.div`
    display: flex;
    align-items: center;
`;
const UploadButtonText = styled.p`
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: -2px;
`;
const SubmittedContainer = styled.div`
    padding: 25px;
    border: 1px solid #9d9d9d;
    box-sizing: border-box;
    /* margin-right: 25px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 150px; */
    /* display: flex; */
    align-items: center;
    border-radius: 10px;
    /* height: calc(100vh - 580px); */
    /* min-height: 100px; */
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    }
`;
const FileContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin-bottom: 15px; */
    &:last-child {
        margin-bottom: 0;
    }
`;
const FileContainerLeft = styled.div`
    display: flex;
    align-items: center;
`;
const FileIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 8px;
`;
const FileName = styled.h6`
    font-size: 16px;
    color: #404040;
    text-transform: capitalize;
`;
const FileContainerRight = styled.div``;
const RemoveIcon = styled.img`
    width: 17px;
    height: 17px;
    object-fit: contain;
    cursor: pointer;
`;
const SubmitButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SubmitButton = styled.button`
    display: inline-block;
    padding: 12px 20px;
    background: #15bf81;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.05rem;
    color: #fff;
    cursor: pointer;
`;
const QuestionsWrapper = styled.div`
    padding: 15px;
    background: #f7f7f7;
    border-radius: 10px;
`;
const QuestionsText = styled.h6`
    color: #404040;
    font-size: 1rem;
    margin-top: 10px;
    padding: 10px 0;
    text-transform: uppercase;
`;
const DownloadButton = styled.button`
    /* background: #917afd; */
    background: rgb(82, 47, 145);
    border-radius: 4px;
    padding: 8px 16px;
    gap: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    color: #fff;
    &:hover {
        opacity: 0.96;
    }
`;
const DownloadButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;
