import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// import profileIcon from "../../../assets/icons/profile-icon.svg";
// import calendarIcon from "../../../assets/icons/calendar-icon.svg";
// import lockIcon from "../../../assets/icons/lock-icon.png";
// import infoIcon from "../../../assets/icons/info.gif";

//svg
import submitIcon from "../../../assets/icons/status-paid-icon.svg";
import pendingIcon from "../../../assets/icons/status-pending-icon.svg";
import lockIcon from "../../../assets/icons/close.png";

export default function AssignmentCard({ type, data, handleClick }) {
    let history = useHistory();

    return (
        <AssignmentCardDiv
            onClick={() => {
                history.push(`submit/${data?.id}`);

                // history.push(`submit/${data?.assignment !== null && data?.id}`);
                // data?.assignment?.status === "Enabled"
                // 	? history.push(
                // 			`submit/${data?.assignment !== null && data?.id}`
                // 	  )
                // 	: data?.assignment?.status === "Submitted" &&
                // 	  alert("You have already submitted this assignment");

                // data?.assignment.status !== "Locked" ||
                //     (data?.assignment.status !== "Submitted" &&
                //         history.push(
                //             `submit/${data?.assignment !== null && data?.id}`
                //         ));
                // history.push(`submit/da3a6be5-dae1-4676-886d-9176a697222d`);
            }}
            // ifLocked={data?.assignment?.status === "Locked" ? true : false}
            // ifSubmitted={
            // 	data?.assignment?.status === "Submitted" ? true : false
            // }
        >
            <TopDiv>
                <Left>
                    {/* <TitleBox>
                    <CapsLetter>{data?.name[0]}</CapsLetter>
                </TitleBox> */}
                    <TitleText>{data?.name}</TitleText>
                    <SubjectCodeBox>
                        <SubjectCodeText>{data?.subject_code}</SubjectCodeText>
                    </SubjectCodeBox>
                    {/* <SubjectName>{data?.name}</SubjectName>
                <SubjectCode>{data?.subject_code}</SubjectCode>
                <TotalQuestions>Total Questions: 4</TotalQuestions> */}
                </Left>
                {/* {data?.assignment?.status === "Locked" && ( */}
                <Right>
                    {/* <Status>{data?.assignment?.status}</Status> */}
                    <StatusButton>
                        {/* {data?.assignment?.status === '' */}
                        <StatusIcon
                            src={
                                data?.status === "Active"
                                    ? pendingIcon
                                    : data?.status === "Submitted"
                                    ? submitIcon
                                    : data?.status === "Closed"
                                    ? lockIcon
                                    : null
                            }
                            alt="icon"
                        />
                        <StatusText>{data?.status}</StatusText>
                    </StatusButton>
                </Right>
                {/* )} */}
            </TopDiv>
            <AssignmentTitle>{data?.assignment?.title}</AssignmentTitle>
            <BottomDiv>
                <BottomLeft>
                    {/* <TotalQuestions>Total Questions: 4</TotalQuestions> */}
                    <TotalQuestions>
                        Total Marks: {data?.assignment?.total_mark}
                    </TotalQuestions>
                    {/* <TotalQuestions> Status: Enabled </TotalQuestions> */}
                    {/* <DescriptionText>
                        {senten.substring(0, 200)}
                        <ReadMore>Read More..</ReadMore>
                    </DescriptionText> */}
                </BottomLeft>
                <BottomRight>
                    {/* {data?.assignment?.status === "Enabled" ? ( */}
                    <SubmitButton>View</SubmitButton>
                    {/* ) : data?.assignment?.status === "Locked" ? ( */}
                    {/* <InfoIcon
						src={infoIcon}
						onClick={handleClick({
							vertical: "top",
							horizontal: "center",
						})}
						alt="icon"
					/> */}
                    {/* ) : ( */}
                    {/* <h5>Submitted</h5> */}
                    {/* )} */}
                </BottomRight>
                {/* {data?.status !== "Locked" || data?.status !== "Submitted" ? (
                    <BottomRight>
                        {type === "ongoing" ? (
                            <SubmitButton>View</SubmitButton>
                        ) : type === "submitted" ? (
                            <MarkText>10 Points</MarkText>
                        ) : type === "closed" ? (
                            <ClosedButton>Closed</ClosedButton>
                        ) : null}
                    </BottomRight>
                ) : (
                    <InfoIcon
                        src={infoIcon}
                        onClick={handleClick({
                            vertical: "top",
                            horizontal: "center",
                        })}
                        alt="icon"
                    />
                )} */}
            </BottomDiv>
        </AssignmentCardDiv>
    );
}

const AssignmentCardDiv = styled.div`
    // position: relative;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    transition: 0.3s;
    opacity: ${(props) => (props.ifLocked ? 0.6 : 1.0)};
    cursor: pointer;
    /* cursor: ${(props) =>
        props.ifLocked || props.ifSubmitted ? "not-allowed" : "pointer"}; */
    /* user-select: ${(props) => (props.ifLocked ? "none" : "auto")}; */
    user-select: none;
    &:hover {
        /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
        /* box-shadow: rgba(67, 71, 85, 0.5) 0px 0px 0.25em,
            rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
        box-shadow: ${(props) =>
            props.ifLocked || props.ifSubmitted
                ? null
                : "rgba(17, 17, 26, 0.1) 0px 0px 16px"};
        transform: ${(props) =>
            props.ifLocked ? null : "translateY(-0.05em)"};
    }
`;
const TopDiv = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const TitleBox = styled.div`
    background: #f5f7fa;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
`;
const CapsLetter = styled.h4`
    /* color: #522e92; */
    /* color: #917afd; */
    color: #313131;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
`;
const TitleText = styled.h6`
    /* color: #917afd; */
    color: #313131;
    /* opacity: 0.5; */
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
`;
const SubjectCodeBox = styled.div`
    background: #f3f4f7;
    border-radius: 6px;
    padding: 10px;
    margin-left: 15px;
`;
const SubjectCodeText = styled.h6`
    font-size: 0.85rem;
    font-weight: 550;
    color: #313131;
    /* color: #522e92; */
    text-transform: uppercase;
`;
const AssignmentTitle = styled.h6`
    font-size: 1.1rem;
    font-weight: 500;
    color: #010101;
    line-height: 1.5rem;
    text-transform: capitalize;
`;
const SubjectCode = styled.h5`
    font-size: 17px;
    font-weight: 500;
    color: #010101;
    line-height: 1.5rem;
    text-transform: uppercase;
`;
const TotalQuestions = styled.span`
    display: inline-block;
    font-size: 0.85rem;
    color: #9d9d9d;
    margin-top: 5px;
    margin-right: 40px;
    &:last-child {
        margin-right: 0;
    }
`;
const Left = styled.div`
    display: flex;
    align-items: center;
`;
const Right = styled.div``;

const StatusButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 90px; */
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #c1c1c1;
    border-radius: 63px;
    padding: 0 8px;
`;
const StatusIcon = styled.img`
    width: 12px;
    display: block;
    object-fit: cover;
    margin-right: 6px;
`;
const StatusText = styled.p`
    color: #404040;
    font-size: 13px;
    margin-bottom: -2px;
`;
const Status = styled.h6``;

const DescriptionText = styled.p`
    font-size: 0.95rem;
    color: #9d9d9d;
`;
const ReadMore = styled.span`
    display: inline-block;
    color: #0000ee;
    margin-left: 5px;
`;
const BottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
`;
const BottomLeft = styled.div`
    /* width: 85%; */
`;
const LeftContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const ImageTag = styled.img`
    margin-right: 10px;
    width: 13px;
`;
const InfoIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    opacity: 1;
    cursor: pointer;
`;
const TextTag = styled.h6`
    font-size: 13px;
    color: #404040;
    display: flex;
    align-items: center;
`;
const BottomRight = styled.div``;
const SubmitButton = styled.button`
    padding: 10px 16px;
    background: #917afd;
    border-radius: 6px;
    font-size: 15px;
    color: #fff;
    cursor: inherit;
`;
const MarkText = styled.p`
    color: #917afd;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.08rem;
    /* width: 100px; */
    margin-left: 12px;
`;
const ClosedButton = styled.button`
    padding: 10px 16px;
    background: #ea1752;
    border-radius: 6px;
    font-size: 15px;
    color: #fff;
    /* cursor: not-allowed; */
    cursor: pointer;
`;
