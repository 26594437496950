import React, { useEffect, useState } from "react";
// import "./Dashboard.css";
import WhatsAppIcon from "./../../../assets/icons/whatsapp.svg";
import GuidancePattern from "./../../../assets/icons/guidance-pattern.png";
import { useHistory, Link, NavLink, useLocation } from "react-router-dom";
// import plusSquare from "../../assets/images/plus-square.svg";
// import Image from "../Image";
import styled from "styled-components";
import LogOutModal from "./LogOutModal";
import logout from "./../../../assets/icons/logout.svg";
// import { Link } from "react-router-dom";

function NavBar({ data, universityModel }) {
	const [isLogOutModal, setLogOutModal] = useState(false);
	const history = useHistory();
	const [selectedItem, setSelectedItem] = useState("Dashboard");
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const toggleOpen = () => {
		setOpen((prev) => !prev);
	};
	// const [active, setActive] = useState(false);

	const confirmHandler = (value) => {
		setSelectedItem(value);
	};

	const isExam = false;

	return (
		<MainContainer>
			<>
				<LogoDiv
					onClick={() => history.push("/")}
					// href="https://www.learnersuae.com/"
				>
					<Logo src={require("./../../../assets/images/logos.png").default} alt="Image" />
				</LogoDiv>

				<Container>
					<div>
						{data.map((item) => (
							<div className="Curriculam" key={item.id}>
								<HeadingDiv>
									<Heading>{item.heading}</Heading>
								</HeadingDiv>
								<NavItemMainDiv>
									{item.data.map((list, ind) => (
										<React.Fragment key={ind}>
											{
												universityModel !== "examination model" &&
												list.text === "Exam" ? null : (
													// } else {
													// 	return (
													<NavItemDiv
														// exact={false}
														exact={item.id === 1 && list.id === 11 ? true : false}
														// exact={
														//     location?.pathname === "/" && "exact"
														// }
														// onClick={() => setSelectedItem(list.text)}
														to={`${list.link}`}
														// active={
														//     selectedItem === list.text
														//         ? true
														//         : false
														// }
														key={list.id}>
														<Image src={list.img} alt="Image" />
														<Title>{list.text}</Title>
													</NavItemDiv>
												)
												// );
											}
										</React.Fragment>
									))}
								</NavItemMainDiv>
							</div>
						))}
					</div>

					{/* <Logout
                    onClick={() => {
                        setLogOutModal(true);
                    }}
                >
                    <Image
                        src={logout}
                        alt="image"
                        width="20px"
                        height="20px"
                        style={{ marginRight: "10px" }}
                        // onClick={handleClick("bottom")}
                    />
                    Logout
                </Logout> */}

					<ExternalLinkDiv>
						<GuidLineDiv>
							<GuidHeading>Academic Support Center</GuidHeading>
							<GuidParagraph>
								Access one-on-one mentorship to address your unique learning needs and enhance your
								academic journey.
							</GuidParagraph>
							<GuidButton
								to={{
									// pathname: "http://Guidance.learnersuae.com/SSM",
									pathname: "https://calendly.com/d/3ds-gmc-gq8/one-on-one-guidance",
								}}
								target="_blank">
								Book Now
							</GuidButton>
						</GuidLineDiv>

						<WhatsAppDiv>
							<WhatsAppHeading>Connect. Ask. Grow</WhatsAppHeading>
							{/* <WhatsAppParagraph>
								Non risus odio nunc duis consectetur lacinia
								varius
							</WhatsAppParagraph> */}
							<WhatsAppButton
								to={{
									pathname: "https://wa.me/+971503770982",
								}}
								target="_blank">
								<WhatsAppIconDiv>
									<WhatsApp alt="icon" src={WhatsAppIcon} />
								</WhatsAppIconDiv>
								<WhatsAppText>Chat with us</WhatsAppText>
							</WhatsAppButton>
						</WhatsAppDiv>
					</ExternalLinkDiv>
				</Container>
			</>
			{isLogOutModal && <LogOutModal isLogOutModal={isLogOutModal} setLogOutModal={setLogOutModal} />}
		</MainContainer>
	);
}

export default NavBar;

const LogoDiv = styled.a`
	display: inline-block;
	// display: flex;
	// justify-content: space-between;
	// background: red;
	// width: 250px;
	cursor: pointer;
	height: 70px;
	/* transition: 0.5s; */
	@media (max-width: 1500px) {
		width: 1px;
		opacity: 0;
		height: 0px;
		:hover {
			display: inline-block;
			height: 70px;
		}
	}
`;
const Container = styled.div`
	overflow-y: scroll;
	height: calc(100vh - 151px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-track {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		display: none;
	}
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	@media (max-width: 1500px) {
		height: calc(100vh - 100px);
	}
	.Curriculam {
		margin-top: 14px;
	}
`;
const Heading = styled.h5`
	color: #999;
	margin-bottom: 10px;
	text-transform: uppercase;
	@media (max-width: 1500px) {
		display: none;
	}
`;
const NavItemMainDiv = styled.div`
	padding-left: 7px;
	// margin-bottom: 25px;
	@media (max-width: 1500px) {
		padding-left: 0;
	}
`;
const Logout = styled.div`
	display: flex;
	justify-content: center;

	border-radius: 8px;
	background-color: #917afd;
	color: #fff;
	padding: 10px;
	font-weight: 600;
	cursor: pointer;
	align-items: center;
	font-size: 14px;
	width: 60%;
	margin: 0 auto;
	@media (max-width: 1500px) {
		display: none;
	}
	@media (max-width: 980px) {
		width: 50%;
	}
`;

const Title = styled.h5`
	font-size: 14px;
	color: #000;
	margin-bottom: -5px;
	@media (max-width: 1500px) {
		display: none;
	}
`;

const Logo = styled.img`
	width: 150px;
	display: block;
	transition: 0.5s;
	// margin-bottom: 35px;
`;

const HeadingDiv = styled.div``;

const NavItemDiv = styled(NavLink)`
	display: flex;
	// justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	cursor: pointer;
	/* background-color: ${(props) => (props.active ? "#F6F0FF" : "#fff")}; */
	background-color: #fff;
	/* opacity: ${(props) => (props.active ? "1" : "0.5")}; */
	opacity: 0.5;
	padding: 10px;
	border-radius: 5px;
	transition: 0.5s;
	&:hover {
		opacity: 1;
	}
	&.active {
		background-color: #f8f4ff;
		opacity: 1;
	}
	// :last-child {
	// 	margin-bottom: 0;
	// }
`;
const Image = styled.img`
	// display: flex;
	// justify-content: space-between;
	margin-right: 8px;
	width: 23px;
	height: 23px;
	object-fit: contain;
	@media (max-width: 1500px) {
		width: 23px;
		height: 23px;
		margin-right: 10px;
	}
`;
const ExternalLinkDiv = styled.div`
	@media (max-width: 1500px) {
		display: none;
	}
`;

const GuidLineDiv = styled.div`
	/* background-image: url({GuidancePattern}); */
	background: linear-gradient(104.59deg, #ea1752 3.09%, #522f91 109.7%);
	border-radius: 12px;
	padding: 12px;
	margin-bottom: 15px;
`;
const GuidHeading = styled.h4`
	color: #ffffff;
	font-size: 15px;
	font-weight: 600;
`;
const GuidParagraph = styled.p`
	margin: 5px 0;
	color: #f5f5f5;
	font-size: 13px;
`;
const GuidButton = styled(Link)`
	color: #f5f5f5;
	font-size: 13px;
	padding: 10px 16px;
	border: 1px solid #ffffff;
	border-radius: 8px;
	cursor: pointer;
	display: inline-block;
`;

const WhatsAppDiv = styled.div`
	background: linear-gradient(90.04deg, #cbe0ff 0.02%, #ffd2ee 99.96%);
	border-radius: 12px;
	padding: 12px;
	margin-bottom: 10px;
`;
const WhatsAppHeading = styled.h4`
	color: #010101;
	font-size: 15px;
	margin-bottom: 15px;
`;
const WhatsAppParagraph = styled.p`
	margin: 5px 0;
	font-size: 13px;
	color: #9d9d9d;
`;
const WhatsAppButton = styled(Link)`
	color: #9d9d9d;
	font-size: 13px;
	padding: 10px 12px;
	border: 1px solid #ffffff;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	display: inline-flex;
`;
const WhatsAppIconDiv = styled.div`
	height: 18px;
	width: 18px;
	margin-right: 8px;
`;
const WhatsApp = styled.img`
	height: 100%;
	width: 100%;
`;
const WhatsAppText = styled.span`
	color: #9d9d9d;
	font-size: 13px;
`;

//main

const MainContainer = styled.div`
	background: #fff;
	padding: 20px;
	width: 300px;
	border-radius: 10px;
	border: 1px solid #e5e5e5;
	position: fixed;
	transition: 0.5s;
	/* z-index: 1000; */
	z-index: 5;

	height: calc(100vh - 40px);

	@media (max-width: 1500px) {
		width: 60px;
		transition: 0.5s;
		padding: 20px 8px;
		&:hover {
			width: 300px;
			padding: 20px;
		}
		&:hover ${LogoDiv} {
			display: block;
			transition: 0.5s;
			width: 150px;
			opacity: 1;
			height: 70px;
		}
		&:hover ${Container} {
			height: calc(100vh - 151px);
		}
		&:hover ${Heading} {
			display: block;
		}
		&:hover ${NavItemMainDiv} {
			padding-left: 7px;
		}
		&:hover ${Logout} {
			display: flex;
		}
		&:hover ${Title} {
			display: block;
		}
		&:hover ${ExternalLinkDiv} {
			display: block;
		}
	}
	@media (max-width: 980px) {
		&:hover {
			width: 270px;
			padding: 15px;
		}
		&:hover ${Container} {
			height: calc(100vh - 142px);
		}
	}
`;
