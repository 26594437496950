import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import nextArrow from "../../../../assets/icons/arrow-right.svg";
// import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default function ProgramAudioSection({
    data,
    subjectName,
    updateContentStatus,
    isNextPractice,
    setOpenPracticeModal,
    setOpenInitialModal,
    practiceData,
}) {
    const audioRef = useRef();

    const handleDiv = () => {
        document.querySelector("#topic-audio-player .rhap_container").focus();
    };
    return (
        <LeftContentsContainer>
            <MediaContainer id="topic-audio-player">
                {/* <ReactAudioPlayer
                    src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                    autoPlay
                    controls
                /> */}
                <AudioPlayer
                    preload="metadata"
                    ref={audioRef}
                    autoPlay
                    src={data.content.audio_content}
                    onPlay={handleDiv}
                    hasDefaultKeyBindings={true}
                    volume={0.5}
                    // other props here
                />
            </MediaContainer>
            <DescriptionContainer>
                <TopSection>
                    <TopUp>
                        <TitleText>{data.content.title}</TitleText>
                    </TopUp>
                    <TopDown>
                        <CourseText>Lesson: {data.lesson_name}</CourseText>
                        {data.status !== "completed" && (
                            <NextTopicButton
                                // onClick={() => updateContentStatus(data.id)}
                                onClick={() => {
                                    !isNextPractice &&
                                        updateContentStatus(data.id);

                                    isNextPractice && practiceData.length > 0
                                        ? setOpenPracticeModal(true)
                                        : updateContentStatus(data.id);
                                }}
                            >
                                <NextTopicText>Mark as completed</NextTopicText>
                                {/* <NextIcon src={nextArrow} /> */}
                            </NextTopicButton>
                        )}
                    </TopDown>
                </TopSection>
                <AboutSubjectText>Description</AboutSubjectText>
                <AboutSubjectDetailedContainer>
                    <AboutSectionDetailedText>
                        {data.content.description}
                    </AboutSectionDetailedText>
                </AboutSubjectDetailedContainer>
            </DescriptionContainer>
        </LeftContentsContainer>
    );
}

const LeftContentsContainer = styled.div`
    padding-right: 5px;
`;
const MediaContainer = styled.div`
    width: 100%;
    border-radius: 10px;
    margin-bottom: 25px;
    height: 220px;
    background: #d1ffee;
    border: 1px solid #15bf81;
    box-sizing: border-box;

    .rhap_container {
        height: 100%;
        background: #d1ffee;
        border: 1px solid #15bf81;
        border-radius: 10px;
    }
    .rhap_progress-indicator {
        background: #8771ef;
        /* opacity: 0.8; */
    }
    .rhap_progress-filled {
        background: #8771ef;
        opacity: 0.7;
    }
    .rhap_download-progress {
        background: rgba(135, 113, 239, 0.19);
    }
    .rhap_volume-bar {
        /* background: rgba(135, 113, 239, 0.19); */
    }
    .rhap_volume-indicator {
        background: #8771ef;
    }
    .rhap_container svg {
        color: #8771ef;
        opacity: 0.8;

        /* color: rgba(135, 113, 239, 0.19); */
    }
`;
const DescriptionContainer = styled.div``;
const TopSection = styled.div`
    margin-bottom: 15px;
`;
const TopUp = styled.div`
    margin-bottom: 5px;
`;
const TitleText = styled.h5`
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #000;
`;
const CourseText = styled.p`
    color: #c1c1c1;
    text-transform: capitalize;
    font-size: 1rem;
`;
const TopDown = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const NextTopicButton = styled.button`
    background: #15bf81;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    padding: 6px 14px;
    cursor: pointer;
`;
const NextTopicText = styled.p`
    margin-right: 5px;
    font-size: 0.9rem;
`;
const NextIcon = styled.img`
    width: 20px;
    object-fit: cover;
`;
const AboutSubjectText = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
    color: #404040;
    text-transform: capitalize;
    margin-bottom: 15px;
`;
const AboutSubjectDetailedContainer = styled.div`
    @media (max-width: 1500px) {
        width: 98%;
    }
`;
const AboutSectionDetailedText = styled.p`
    color: #9d9d9d;
    font-size: 0.9rem;
    /* width: 99%; */
`;
