import React, { useRef, useEffect } from "react";
import styled from "styled-components";

// importing Icons
import subtopicCompletedIcon from "../../../../assets/icons/subtopic-completed-icon.svg";
import subtopicAudioIcon from "../../../../assets/icons/subtopic-audio-icon.svg";
import subtopicVideoIcon from "../../../../assets/icons/subtopic-video-icon.svg";
import subtopicNotesIcon from "../../../../assets/icons/subtopic-notes-icon.svg";

export default function SubTopicCard({
	data,
	selectedContent,
	getSingleContent,
}) {
	const myRef = useRef(null);

	const executeScroll = () =>
		myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

	useEffect(() => {
		if (data.id === selectedContent?.id) {
			executeScroll();
		}
	}, [selectedContent]);

	return (
		<SubTopicBox
			ref={myRef}
			className={data.id === selectedContent?.id ? "selected" : null}
			onClick={() => getSingleContent(data.id)}
			active={data.id === selectedContent?.id ? true : false}
			// onClick={() => setSelectedContent(data)}
		>
			<LeftPart>
				<TitleDiv>
					{/* {data.status === "completed" && ( */}
					<MediaIcon
						src={
							data.content_type === 2
								? subtopicVideoIcon
								: data.content_type === 10
								? subtopicAudioIcon
								: data.content_type === 4 || 6
								? subtopicNotesIcon
								: subtopicNotesIcon
						}
						alt="icon"
					/>
					{/* )} */}
					<TitleText>{data.content.title}</TitleText>
				</TitleDiv>
				<DurationText>{data?.subtopic_duration}</DurationText>
			</LeftPart>
			<RightPart>
				{data.status === "completed" && (
					<MediaIcon src={subtopicCompletedIcon} alt="icon" />
				)}

				{/* : (
					<MediaIcon
						src={
							data.status === "completed"
								? subtopicCompletedIcon
								: data.content_type === 2
								? subtopicVideoIcon
								: data.content_type === 10
								? subtopicAudioIcon
								: data.content_type === 4 || 6
								? subtopicNotesIcon
								: subtopicNotesIcon
						}
						alt="icon"
					/>
				)} */}
			</RightPart>
		</SubTopicBox>
	);
}

const SubTopicBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 10px;
	background: ${(props) => (props.active ? "#E7E7E7" : "#fff")}; //#F6F0FF
	border-left: ${(props) =>
		props.active ? "3px solid red" : "3px solid #fff;"}; //#F6F0FF
	border-bottom: 1px solid #ededed;
	box-sizing: border-box;
	/* border-left: 3px solid #fff; */
	/* box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    /* &:last-child {
        border-bottom: 0px;
    } */
	/* animation: rotateMenu 0.3s ease-in-out forwards;
    transform-origin: top center; */
	cursor: pointer;
	&.selected {
		/* border-width: 3px 0 3px 3px; */
		border-style: solid;
		-webkit-border-image: -webkit-gradient(
				linear,
				0 0,
				0 100%,
				from(rgb(234, 58, 83)),
				to(rgb(84, 47, 145))
			)
			1 100%;
		-webkit-border-image: -webkit-linear-gradient(
				rgb(234, 58, 83),
				rgb(84, 47, 145)
			)
			1 100%;
		-moz-border-image: -moz-linear-gradient(
				rgb(234, 58, 83),
				rgb(84, 47, 145)
			)
			1 100%;
		-o-border-image: -o-linear-gradient(rgb(234, 58, 83), rgb(84, 47, 145))
			1 100%;
		border-image: linear-gradient(
				to bottom,
				rgb(234, 58, 83),
				rgb(84, 47, 145)
			)
			1 100%;
	}
	:hover {
		/* background: #f4f4f4ab; */
		background: ${(props) => (props.active ? null : "#f4f4f4ab")};
		border-color: ${(props) => (props.active ? null : "#f4f4f4ab")};
	}
`;
const LeftPart = styled.div``;
const TitleDiv = styled.div`
	display: flex;
	align-items: center;
`;
const DurationText = styled.div`
	font-size: 0.7rem;
	color: #000;
	opacity: 0.6;
`;
const TitleText = styled.h6`
	font-size: 15px;
	margin-bottom: -3px;
`;
const RightPart = styled.div``;
const MediaIcon = styled.img`
	display: block;
	width: 30px;
	height: 30px;
	object-fit: cover;
`;
