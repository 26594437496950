import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ProgressBar from "../ProgressBar";
import CourseImage from "../../../assets/images/course-image-dashboard.png";
import PlayIcon from "../../../assets/icons/play-icon-dashboard.svg";
import AudioIcon from "../../../assets/icons/audio-icon.svg";
import DocumentIcon from "../../../assets/icons/document-icon.svg";

export default function CurrentCourseCard({ data }) {
	let history = useHistory();

	return (
		<Container onClick={() => history.push(`/program/subject/${data.id}`)}>
			<Top>
				<ToppImage
					src={data?.image !== null ? data.image : CourseImage}
					alt="image"
				/>
			</Top>
			<Bottom>
				<Left>
					{data?.content?.content_type === 2 ? (
						<Icon src={PlayIcon} alt="icon" />
					) : data?.content?.content_type === 6 ? (
						<Icon src={DocumentIcon} alt="icon" />
					) : data?.content?.content_type === 10 ? (
						<Icon src={AudioIcon} alt="icon" />
					) : null}
				</Left>
				<Right>
					<Heading>
						{data?.name}: {data?.content?.topic_name}
					</Heading>
					<Title>{data.content.content.title}</Title>
					{/* <ProgressBar
						current={data?.progress}
						total={100}
						top="not-required"
						type="programCard"
					/> */}
				</Right>
			</Bottom>
		</Container>
	);
}

const Top = styled.div`
	width: 100%;
	border-radius: 16px 16px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	/* border: #fff; */
`;
const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	border: 1px solid #e5e5e5;
	border-radius: 0px 0px 16px 16px;
	border-top: none;
`;
const ToppImage = styled.img`
	width: 100%;
	height: 260px;
	object-fit: cover;
	display: block;
	border-radius: 16px 16px 0px 0px;
	overflow: hidden;
`;
const Container = styled.div`
	border-radius: 0px 0px 16px 16px;
	cursor: pointer;
	transition: 0.3s;
	padding: 3px;
	&:hover {
		/* background: rgba(0, 0, 0, 0.01); */
		transform: translateY(-1.5px) translateX(1.5px);
		/* box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 2px 2px rgb(0 0 0 / 5%); */
		/* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05); */

		/* border: 1px solid #917afd; */

		${Bottom} {
			/* border: 1px solid rgba(0, 0, 0, 0.18); */
			border: 1px solid #917afd;
			border-top: none;
			box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 2px 2px rgb(0 0 0 / 5%);
		}

		${Top} {
			border: 1px solid #917afd;
			border-bottom: none;
		}
	}
`;

const Left = styled.div`
	width: 40px;
`;
const Icon = styled.img`
	width: 100%;
	display: block;
`;
const Right = styled.div`
	width: calc(100% - 60px);
`;
const Heading = styled.p`
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #9d9d9d;
`;
const Title = styled.p`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-transform: capitalize;
	color: #010101;
	/* margin-bottom: 0.5rem; */
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
`;
